import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {CategoryModel} from "../../lib/models/index.js";
import {API_URL} from "../../lib/core-x/Connection.js";

const Categories = Modeler.use({
    model: CategoryModel,
    url: `${API_URL}categories`
})

export const FetchCategoriesThunk = createAsyncThunk('categories/fetch', async function(config = undefined, {getState}){

    let {type} = ( config ?? {} );

    let state = getState();
    let {categories} = state.categorySlice;

    if (!type && categories.length) {
        return categories;
    }

    return await Modeler.promisify(Categories.take, {});

});
