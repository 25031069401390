import React, {useEffect, useMemo, useState} from "react";
import DefaultImage from "../components/DefaultImage.js";
import SideItem from "../components/Sidebar/SideItem.js";
import {useLocation} from "react-router-dom";
import Modeler from "../lib/core-x/index.js";
import {PageModel} from "../lib/models/index.js";
import Markdown from "./content-types/Markdown.js";
import Hierarchy from "./content-types/Hierarchy.js";
import {useSelector, useStore} from "react-redux";
import {SelectMenus} from "../redux-store/slices/menu-slice.js";
import {SelectCurrentPage, SelectPageIsFetching} from "../redux-store/slices/page-slice.js";
import {FetchPageThunk} from "../redux-store/thunks/page-thunk.js";
import PulseSide from "../components/core/loaders/PulseSide.js";
import UnderConstruction from "../pages/UnderConstruction.js";
import PulsePage from "../components/core/loaders/PulsePage.js";
import Error404 from "../pages/Error404.js";

export default function(){

    let location = useLocation();

    const menus = useSelector(SelectMenus);
    const pageInfo = useSelector(SelectCurrentPage);
    const isPageFetching = useSelector(SelectPageIsFetching);
    const store = useStore();

    useEffect(async () => {
        window.scrollTo(0, 0);
        store.dispatch(FetchPageThunk({slug: location.pathname}));
    }, [location]);

    // Page Update

    const {title, content, contentType, sidebar} = useMemo(() => {
        return pageInfo;
    }, [pageInfo]);


    return (!isPageFetching && pageInfo.slug)? (
        <div className="my-container pt-4 pb-40 flex lg:flex-row flex-col items-start gap-10">
        <div className="flex-grow">
            <Markdown data={pageInfo} isPage/>
        </div>
        {sidebar? (
            <div className="lg:w-1/3 w-full min-w-fit p-5 bg-white mt-4 flex flex-col gap-2 rounded-xl" style={{'minWidth': '33.33%'}}>
                <p className="text-2xl">
                    {sidebar.label}
                </p>
                <span className="side-splitter-1"/>
                {sidebar.children?.map((item, index) => (
                    <React.Fragment key={index}>
                        <SideItem path={item.children.length? "#": item.slug} isSelected={item.slug === location.pathname.substring(1) || item.children?.some(a=>a.slug === location.pathname.substring(1))}>
                            {item.label}
                        </SideItem>
                        {item?.children?.map((a,i) => (
                            <SideItem key={i} isSelected={a.slug === location.pathname.substring(1)} isChild={true} path={a.slug}>
                                {a.label}
                            </SideItem>
                        )) || ''}
                    </React.Fragment>
                )) || ''}
            </div>
        ): ''}
    </div>): (
        <>
            {isPageFetching && !pageInfo.error? <PulsePage/>: <Error404/>}
        </>
    );
}
