import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {MediaModel} from "../../lib/models/index.js";
import {API_URL, axiosGet} from "../../lib/core-x/Connection.js";

export const FetchMediaThunk = createAsyncThunk('media/fetchById', async function({hash}, {getState}){
    let state = getState();
    let {items} = state.mediaSlice;

    let selected = items.find(p => p.hash + '' === hash + '');
    if(selected){
        return selected;
    }

    return await axiosGet(`${API_URL}media/`+hash);
});
