import {createSlice} from "@reduxjs/toolkit";
import {FetchMenusThunk} from "../thunks/menu-thunk.js";

const menuSlice = createSlice({
    name: "menuSlice",
    initialState: {
        menus: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(FetchMenusThunk.fulfilled, function(state, action){
           state.menus = action.payload;
        });
    }
});

export const SelectMenus = function(state){
    return state.menuSlice.menus;
};

export default menuSlice.reducer;
