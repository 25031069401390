import React from "react";

export default function({count, type, isPrimary=false}){
    return (
        <div className={(isPrimary? 'text-primary border-primary-focused': 'border-neutral-20') + " w-14 text-center border flex flex-col py-1.5 gap-2 rounded text-s-medium"}>
            <p>{count}</p>
            <p>{type}</p>
        </div>
    )
}
