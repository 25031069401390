import React from "react";
import VoteBadge from "./VoteBadge.js";
import {Link} from "react-router-dom";
import QuestionInfo from "./QuestionInfo.js";

export default function({title, slug, views, votes, answers, category, lastChange='', user}){
    return (
        <div className="bg-white rounded-md flex flex-row p-4 gap-4 items-center">
            {user?.image? (
                <Link to={'/pengguna/' + user?.username}>
                    <img src={user.image} className="w-11 h-11 object-cover rounded-md"/>
                </Link>
            ): (
                <Link to={'/pengguna/' + user?.username} className="bg-neutral-30 border border-neutral-40 rounded-md h-fit w-fit p-3">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.1667 5.83366C14.1667 7.8587 12.5251 9.50033 10.5 9.50033C8.475 9.50033 6.83337 7.8587 6.83337 5.83366C6.83337 3.80861 8.475 2.16699 10.5 2.16699C12.5251 2.16699 14.1667 3.80861 14.1667 5.83366Z" fill="#A2A6AD" stroke="#A2A6AD"/>
                        <path d="M17.5743 18.2497H3.4258C3.48063 15.1973 6.54262 12.583 10.5 12.583C14.4575 12.583 17.5195 15.1973 17.5743 18.2497Z" fill="#A2A6AD" stroke="#A2A6AD"/>
                    </svg>
                </Link>
            )
            }
            <div className="flex flex-col gap-2 flex-grow">
                <Link to={`/forum/pertanyaan/${slug}`} className="text-l-medium w-fit">{title}</Link>
                <QuestionInfo category={category} lastChange={lastChange} views={views}/>
            </div>
            <div className="flex flex-row gap-2">
                <VoteBadge count={votes} type="votes"/>
                <VoteBadge count={answers} type="ans" isPrimary={true}/>
            </div>
        </div>
    )
}
