import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SelectMenus} from "../redux-store/slices/menu-slice.js";
import {FetchMenusThunk} from "../redux-store/thunks/menu-thunk.js";

export const useMenu = function(){
  const selector = useSelector(SelectMenus);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!selector.length){
      dispatch(FetchMenusThunk());
    }
  }, [selector]);

  return {
    data: selector,
  }
}
