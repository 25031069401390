import DefaultImage from "../DefaultImage.js";
import {Link, useHistory, useLocation} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {BASE_URL} from "../../lib/core-x/Connection.js";
import {resolveImageURL, resolveURL} from "../../lib/functions.js";
import PulseCard from "../core/loaders/PulseCard.js";
import PostCard from "./PostCard.js";
import {useDispatch, useSelector} from "react-redux";
import {
    SelectLoadingPostError,
    SelectLoadingRecentPosts,
    SelectRecentPosts
} from "../../redux-store/slices/post-slice.js";
import {FetchPostBySlug, FetchRecentPostsThunk} from "../../redux-store/thunks/post-thunk.js";
import {SelectCategories} from "../../redux-store/slices/category-slice.js";

export default function PostSidebar({posts}){
    const [activeIndex, setActiveIndex] = useState(0);

    const categories = useSelector(SelectCategories);
    const dispatch = useDispatch();
    const recentPosts = useSelector(SelectRecentPosts);
    const isFetchingRecentPosts = useSelector(SelectLoadingRecentPosts);
    const isError = useSelector(SelectLoadingPostError);

    useEffect(async () => {
        dispatch(FetchRecentPostsThunk({}));
    }, [activeIndex]);

    return (
      <div className="lg:px-5 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
              <p className='text-2xl text-primary font-semibold'>
                  Berita Terbaru
              </p>
          </div>
          <div className="flex flex-col gap-4">
              {
                  isFetchingRecentPosts? (
                    [1,2,3].map(a=>
                      <PulseCard
                        className='horizontal'
                        key={a}/>
                    )
                  ):recentPosts.slice(0, 3).map((post) => (
                    <PostCard
                      title={post.title}
                      slug={post.slug}
                      image={post.image}
                      date={post.publishedAt}
                      key={post.slug}
                      categorySlug={post.categorySlug}
                      category={post.category}/>
                  ))
              }
          </div>
      </div>
    )
}
