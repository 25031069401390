import {createSlice} from "@reduxjs/toolkit";
import {FetchPostBySlug, FetchPostCountThunk, FetchPostsThunk, FetchRecentPostsThunk} from "../thunks/post-thunk.js";
import {parse} from "postcss";
import {FetchHierarchyThunk} from "../thunks/hierarchy-thunk.js";

const hierarchySlice = createSlice({
    name: "hierarchySlice",
    initialState: {
        items: [],
        currentItem: {},
    },
    reducers: {},
    extraReducers(builder){
        builder.addCase(FetchHierarchyThunk.fulfilled, function(state, action){
            if(!state.items.some(a => a.hash === action.payload.hash)){
                state.items = [
                    ...state.items,
                    action.payload
                ]
            };
            state.currentItem = action.payload;
        });
    }
});

export const SelectCurrentHierarchy = function(state){
    return state.hierarchySlice.currentItem;
};


export default hierarchySlice.reducer;
