import React from "react";
import Navbar from "./Navbar.js";
import {Outlet} from "react-router-dom";
import Footer from "./Footer.js";
import {useSelector} from "react-redux";
import {SelectMenus} from "../redux-store/slices/menu-slice.js";

export default function (){

    const menus = useSelector(SelectMenus);

    return (
        <>
            <Navbar menus={menus}/>
            <div className="pt-20 bg-primary-surface min-h-screen">
                <Outlet/>
            </div>
            <Footer/>
        </>
    );
}
