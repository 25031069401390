import React from 'react';

import bannerImage from '../assets/banner.png';


export default function Banner({title, children}){
  return (
    <div className={"relative w-full h-[550px]"}>
      <img src={bannerImage} className={'w-full h-full object-cover absolute inset-0'}/>
      <span className={'absolute block w-full h-full inset-0 bg-[#756348]/[0.7]'}/>
      <div className={'w-full h-full relative text-white flex items-center justify-center flex-col gap-4'}>
        <h1 className={'text-5xl font-bold mx-auto max-w-3xl text-center'}>
          {title}
        </h1>
        <p className={'max-w-2xl text-xl text-center'}>
          {children}
        </p>
      </div>
    </div>
  )
}
