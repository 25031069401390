import React, {useState} from "react";
import Image from "../components/core/Image.js";
import GalleryDefaultImage from "../components/GalleryDefaultImage.js";
import ImgsViewer from "react-images-viewer";

export default function({title, items, description}){

    const [currentImage, setCurrentImage] = useState(null);

    return (
        <div className={"flex flex-col gap-2"}>
            <div>
              <a className="text-heading-3">
                {title}
              </a>
              {description.length? (
                <p className={'text-base mt-3'}>
                  {description}
                </p>
              ): ''}
            </div>
            <span className="block h-0.5 bg-neutral-20 w-full mb-2"/>
            <div className="grid grid-cols-4 gap-2 -m-0.5">
                {items.map((a, index)=>(
                    <div onClick={() => setCurrentImage(index)} key={index} className={"cursor-pointer hover:opacity-80 duration-200 transition"}>
                        {a.src? <img className={'w-full h-48 object-cover rounded'} src={a.small}/>: <GalleryDefaultImage/>}
                    </div>
                ))}
            </div>
            <ImgsViewer
                imgs={items}
                // currImg={currentImage !== null? items[currentImage]: null}
                currImg={currentImage}
                isOpen={currentImage !== null}
                onClickPrev={()=>setCurrentImage(currentImage - 1)}
                onClickNext={()=>setCurrentImage(currentImage + 1)}
                onClose={()=>setCurrentImage(null)}
            />
        </div>
    )
}
