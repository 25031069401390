import React from "react";
import {Link} from "react-router-dom";
import DefaultImage from "../../DefaultImage.js";

const PulseBanner = function(){
    return (
        <div className="flex flex-col gap-4 animate-pulse px-4">
            <div className={'block w-32 h-8 bg-gray-400 animate-pulse rounded'}/>
            <div className={'flex flex-col gap-12'}>
                <DefaultImage className="py-0 h-40 my-0 rounded-xl"/>
                <DefaultImage className="py-0 h-40 my-0 rounded-xl"/>
                <DefaultImage className="py-0 h-40 my-0 rounded-xl"/>
                <DefaultImage className="py-0 h-40 my-0 rounded-xl"/>
            </div>
        </div>
    )
}

export default PulseBanner
