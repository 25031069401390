import React from "react";
import Banner from "../layouts/Banner.js";
import banner from "../assets/banner.png";


export default function Profile(){
    return (
        <div>
            <Banner title={"Peternakan Domba Farm UMKM"}>
              Peternakan dampit adalah peternakan yang dikembangkan dalam bentuk digital.
            </Banner>
          <div className={'my-container py-24 h-screen'}>
            <div className="flex justify-start items-end w-full h-full relative bg-[#756348]">
              <div className="flex flex-col justify-center items-center self-stretch w-1/2 flex-grow relative gap-2.5">
                <div className="max-w-md mx-auto flex flex-col gap-2">
                  <p className="text-xl font-medium text-center text-white">
                    Peternakan Domba Farm UMKM
                  </p>
                  <p className="text-3xl font-bold text-center text-[#ceb58b]">
                    Peternakan Domba Farm UMKM adalah peternakan berbasis websites
                  </p>
                  <p className="text-xl text-center text-white">
                    Pada websites ini menyediakan layanan seperti investasi, pemasaran, dan tips untuk
                    peternakan
                  </p>
                </div>
              </div>
              <img className="self-stretch flex-grow w-1/2 object-cover" src={banner}/>
            </div>
          </div>
        </div>
    )
}
