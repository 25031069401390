import React, {useState} from "react";
import ImgsViewer from "react-images-viewer";

export default function(props){
    const [isDisplayed, setIsDisplayed] = useState(false);

    return (
        <>
            <img {...props} onClick={()=>setIsDisplayed(true)}/>
            <ImgsViewer
                imgs={[props]}
                currImg={0}
                isOpen={isDisplayed}
                onClose={()=>setIsDisplayed(false)}
            />
        </>
    )
}
