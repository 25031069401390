import React, {useMemo} from "react";
export default function({page, totalPages, onPageChange}){

    const pageNumber = useMemo(()=>parseInt(page), [page]);
    const total = useMemo(()=>parseInt(totalPages), [totalPages]);

    const pageNumbers = useMemo(()=>{

        const numbers = [];
        const hasBefore = pageNumber - 2 >= 1;
        const hasAfter = pageNumber + 2 <= total;

        if(pageNumber === 1){
            numbers.push(pageNumber);
            if(pageNumber + 2 < total){
                numbers.push(pageNumber + 2);
            }
        }

        if(pageNumber - 1 >= 1){
            numbers.push(pageNumber - 1);
        }

        if(pageNumber !== 1 && pageNumber !== total){
            numbers.push(pageNumber);
        }

        if(pageNumber + 1 <= total){
            numbers.push(pageNumber + 1);
        }

        if(pageNumber === total){
            if(pageNumber - 2 > 0){
                numbers.push(pageNumber - 2);
            }
            if(pageNumber !== 1)
                numbers.push(total);
        }

        return {
            numbers: numbers.sort((a,b) => a - b),
            hasBefore,
            hasAfter
        }

    }, [totalPages, page]);

    const changePage = function(num){
        if(num > 0 && num <= total)
            onPageChange(num);
    }

    const activeCaret = 'text-primary hover:text-primary-hover font-semibold';

    if(!total){
        return '';
    }
    return (
        <div className={'pb-12 flex flex-row w-full items-center gap-6 items-center'}>
            {pageNumber !== 1 && <button className={activeCaret} onClick={()=>changePage(pageNumber-1)}>
                Kembali
            </button>}
            <div className="flex flex-row items-center gap-4">
                {pageNumbers.hasBefore? (<span>...</span>): ''}
                {pageNumbers.numbers.map(num => (
                    <React.Fragment key={num}>
                        {num === pageNumber? (
                            <span className={activeCaret}>{num}</span>
                        ): (
                            <span className={"cursor-pointer text-primary-hover text-l-regular"} onClick={()=>changePage(num)}>{num}</span>
                        )}
                    </React.Fragment>
                ))}
                {pageNumbers.hasAfter? (<span>...</span>): ''}
            </div>

            {pageNumber !== total && <button className={activeCaret} onClick={()=>changePage(pageNumber+1)}>
                Selanjutnya
            </button>}
        </div>
    )
}
