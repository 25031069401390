import {createSlice} from "@reduxjs/toolkit";
import {FetchPostsThunk} from "../thunks/post-thunk.js";
import {FetchPageThunk} from "../thunks/page-thunk.js";

const pageSlice = createSlice({
    name: "pageSlice",
    initialState: {
        currentPage: {},
        pages: [],
        isFetching: false,
    },
    reducers: {},
    extraReducers(builder){

        builder.addCase(FetchPageThunk.pending, function(state){
           state.isFetching = true;
        });

        builder.addCase(FetchPageThunk.rejected, function(state, action){
            state.currentPage = {error: '404'};
            state.isFetching = false;
        })

        builder.addCase(FetchPageThunk.fulfilled, function(state, action){

            let page = action.payload;
            if(!state.pages.some(p => p.slug === page.slug)){
                state.pages = [
                    ...state.pages,
                    page,
                ]
            }

            state.currentPage = page;
            state.isFetching = false;
        })
    }
});

export const SelectCurrentPage = function(state){
    return state.pageSlice.currentPage;
};

export const SelectPageBySlug = function(slug){
    return function(state){
        return state.pageSlice.find(p => p.slug === slug);
    }
}

export const SelectPageIsFetching = function(state){
    return state.pageSlice.isFetching;
}

export default pageSlice.reducer;
