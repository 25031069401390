import { configureStore } from '@reduxjs/toolkit'
import postSlice from "./slices/post-slice.js";
import menuSlice from "./slices/menu-slice.js";
import pageSlice from "./slices/page-slice.js";
import categorySlice from "./slices/category-slice.js";
import gallerySlice from "./slices/gallery-slice.js";
import hierarchySlice from "./slices/hierarchy-slice.js";
import mediaSlice from "./slices/media-slice.js";

export default configureStore({
    reducer: {
        postSlice,
        menuSlice,
        pageSlice,
        categorySlice,
        gallerySlice,
        hierarchySlice,
        mediaSlice,
    },
})
