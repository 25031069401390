import React, {useEffect, useMemo, useState} from "react";
import PostCard from "../components/posts/PostCard.js";
import DefaultImage from "../components/DefaultImage.js";
import {Link, useLocation, useParams} from "react-router-dom";
import Modeler from "../lib/core-x/index.js";
import {CountModel, PostModel} from "../lib/models/index.js";
import {API_URL} from "../lib/core-x/Connection.js";
import Pagination from "../components/core/Pagination.js";
import PulseCard from "../components/core/loaders/PulseCard.js";
import {useSelector, useStore} from "react-redux";
import {
    SelectCurrentPostCount,
    SelectCurrentPosts,
    SelectLoadingPosts, SelectLoadingPostsCount, SelectLoadingRecentPosts,
    SelectRecentPosts
} from "../redux-store/slices/post-slice.js";
import {SelectCategoryBySlug} from "../redux-store/slices/category-slice.js";
import {FetchPostCountThunk, FetchPostsThunk} from "../redux-store/thunks/post-thunk.js";
import PulsePagination from "../components/core/loaders/PulsePagination.js";

const Counter = Modeler.use({
    model: CountModel,
    url: API_URL + 'posts'
})

const limit = 8;

export default function({}){

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(parseInt(new URLSearchParams(location.search).get("page") ?? 1))
    const store = useStore()
    const {slug} = useParams()

    const posts = useSelector(SelectCurrentPosts)
    const totalPosts = useSelector(SelectCurrentPostCount);
    const category = useSelector(SelectCategoryBySlug(slug))

    const isFetching = useSelector(SelectLoadingPosts);
    const isFetchingPostCount = useSelector(SelectLoadingPostsCount);

    useEffect(()=>{
        store.dispatch(FetchPostsThunk({page, limit, category: slug}))
        store.dispatch(FetchPostCountThunk({category: slug}));
        window.history.replaceState(null, "React State", "?page=" + page);
    }, [page]);

    const changePage = function(num){
        setIsLoading(true);
        setPage(num);
    }

    const totalPages = useMemo(()=>{
        return Math.floor((totalPosts - 1) / limit + 1)
    }, [totalPosts]);


    return (
        <div className="my-container pb-24">
            <p className="text-center text-xl mb-4 mt-2">
                Hasil pencarian untuk kategori
                <span className="text-primary">
                    &nbsp;{category?.name}
                </span>
            </p>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 pb-12 transition-grid-template h-fit duration-300">
                {(isFetching)? (
                    [1, 2, 3, 4, 5, 6, 7, 8].map(num => (
                        <PulseCard key={num}/>
                    ))
                ): posts.map(post => (
                    <PostCard
                        key={post.hash}
                        title={post.title}
                        category={post.category}
                        date={post.publishedAt}
                        categorySlug={post.categorySlug}
                        image={post.image}
                        slug={post.slug}/>
                ))}
            </div>
            {/*PAGINATION*/}
            {isFetchingPostCount? (
                <PulsePagination/>
            ): (
                <Pagination page={page} totalPages={totalPages} onPageChange={changePage}/>
            )}
        </div>
    )
}
