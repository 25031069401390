import React from "react";
import underConstruction from '../assets/under-construction.png';
import {Link} from "react-router-dom";
export default function ({}){
    return (
        <div className='flex my-container pb-32 pt-24 items-center justify-center text-neutral-100 text-center'>
            <div className="max-w-2xl h-screen -mt-12 pb-8 h-full w-full mx-auto text-center flex flex-col">
                <div className='lg:px-32 md:px-16 px-3 mb-8'>
                    <img src={underConstruction} className={"w-full"}/>
                </div>
                <div className={'flex-grow flex flex-col gap-2 items-center'}>
                    <p className='text-heading-1'>
                        Under Construction
                    </p>
                    <p className='text-l-regular'>
                        Kami sedang membuat banyak perbaikan dan akan kembali secepatnya.
                    </p>
                    <Link to={'/'} className="button mt-2">
                        Kembali
                    </Link>
                </div>
            </div>
        </div>
    )
}
