import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {HierarchyModel, PageModel} from "../../lib/models/index.js";
import {API_URL, axiosGet} from "../../lib/core-x/Connection.js";

const Hierarchy = Modeler.use({
    model: HierarchyModel,
    url: `${API_URL}hierarchy/`
})

export const FetchHierarchyThunk = createAsyncThunk('hierarchies/fetchById', async function({id}, {getState}){
    let state = getState();
    let {items} = state.hierarchySlice;

    let selected = items.find(p => p.hash + '' === id + '');
    if(selected){
        return selected;
    }

    return await axiosGet(`${API_URL}hierarchy/`+id);
});
