import React from "react";
import {Link} from "react-router-dom";

export default function({children, path, isChild, isSelected}){
    let classes = "py-1.5 text-sm leading-none";
    if(isChild){
        classes += ' pl-4';
    }
    if(isSelected){
        classes += ' text-neutral-100';
    } else {
        classes += ' text-neutral-90';
    }
    return (
        <>
            <Link to={path}>
                <p className={classes}>
                    {children}
                </p>
            </Link>
            <span className="side-splitter-2"/>
        </>
    )
}
