import React from "react";

export default function BaseAlert({children}){
  return (
    <div className="flex flex-row h-fit rounded-lg bg-secondary-main overflow-hidden text-secondary-text gap-6">
      <div className="w-14 block bg-secondary-text flex items-center justify-center">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 2.6665C8.65335 2.6665 2.66669 8.65317 2.66669 15.9998C2.66669 23.3465 8.65335 29.3332 16 29.3332C23.3467 29.3332 29.3334 23.3465 29.3334 15.9998C29.3334 8.65317 23.3467 2.6665 16 2.6665ZM15 10.6665C15 10.1198 15.4534 9.6665 16 9.6665C16.5467 9.6665 17 10.1198 17 10.6665V17.3332C17 17.8798 16.5467 18.3332 16 18.3332C15.4534 18.3332 15 17.8798 15 17.3332V10.6665ZM17.2267 21.8398C17.16 22.0132 17.0667 22.1465 16.9467 22.2798C16.8134 22.3998 16.6667 22.4932 16.5067 22.5598C16.3467 22.6265 16.1734 22.6665 16 22.6665C15.8267 22.6665 15.6534 22.6265 15.4934 22.5598C15.3334 22.4932 15.1867 22.3998 15.0534 22.2798C14.9334 22.1465 14.84 22.0132 14.7734 21.8398C14.7067 21.6798 14.6667 21.5065 14.6667 21.3332C14.6667 21.1598 14.7067 20.9865 14.7734 20.8265C14.84 20.6665 14.9334 20.5198 15.0534 20.3865C15.1867 20.2665 15.3334 20.1732 15.4934 20.1065C15.8134 19.9732 16.1867 19.9732 16.5067 20.1065C16.6667 20.1732 16.8134 20.2665 16.9467 20.3865C17.0667 20.5198 17.16 20.6665 17.2267 20.8265C17.2934 20.9865 17.3334 21.1598 17.3334 21.3332C17.3334 21.5065 17.2934 21.6798 17.2267 21.8398Z" fill="white"/>
        </svg>
      </div>
      <div className="w-1/2 flex-grow py-4">
        {children}
      </div>
    </div>
  )
}
