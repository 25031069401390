import {dateFormat, getSystemFile, resolveImageURL} from "../functions.js";

export const BlogModel = function(dataResponse){
    return {
        title: dataResponse.title,
        content: dataResponse.content,
        publishedAt: dataResponse.published_at,
        author: dataResponse.author || null,
        tags: dataResponse.tags || [],
    };
}

export const MenuModel = function(dataResponse){
    return {
        label: dataResponse.label,
        slug: dataResponse.slug,
        children: dataResponse.children
    }
}

export const PageModel = function(dataResponse){

    return {
        title: dataResponse.title,
        slug: '/' + dataResponse.slug,
        content: dataResponse.content,
        image: dataResponse.image,
        contentType: dataResponse['content_type'] || 'md',
        sidebar: dataResponse.sidebar || null,
    }
}

export const PostModel = function(dataResponse){

    try {
        dataResponse.meta = JSON.parse(dataResponse.meta);
    } catch (e){}

    return {
        id: dataResponse.hash,
        title: dataResponse.title,
        content: dataResponse.content,
        image: dataResponse.media,
        slug: dataResponse.slug,
        tags: dataResponse.meta?.tags,
        category: dataResponse.category?.name,
        categoryId: dataResponse.category_id,
        categorySlug: dataResponse.category?.slug,
        publishedAt: dataResponse['published_at'],
        author: dataResponse.author ? AuthorModel(dataResponse.author): null,
    }
}

export const AuthorModel = function(dataResponse){
    return {
        name: dataResponse['full_name'],
        username: dataResponse.username,
        id: dataResponse.hash,
        email: dataResponse.email,
    }
}

export const CategoryModel = function(dataResponse){
    return {
        id: dataResponse.hash,
        name: dataResponse.name,
        label: dataResponse.label,
        slug: dataResponse.slug,
    }
}

export const CountModel = function(dataResponse){
    return {
        total: dataResponse?.total || dataResponse
    }
}

export const QuestionModel = function(dataResponse){

    let { baseComment } = dataResponse;

    let requiredFields = {
        id: dataResponse.hash,
        title: dataResponse.title,
        views: dataResponse.views,
        slug: dataResponse.slug,
        lastStatus: dataResponse['last_status'],
        publishedAt: dateFormat(dataResponse['published_at']),
        category: dataResponse.category,
        commentsCount: dataResponse['comments_count'],
        user: {
            ...(dataResponse?.user || {}),
            fullName: dataResponse['full_name'] || dataResponse?.user?.['full_name']
        },
        votes: dataResponse['votes_count'] || 0,
        userVoteComments: dataResponse.userVoteComments || [],
    }

    if(!baseComment)
        return requiredFields;

    return {
        ...requiredFields,
        ...Comment(baseComment),
    }
}

export const Comment = function(dataResponse){
    return {
        user: {
            ...dataResponse.user,
            fullName: dataResponse?.user?.['full_name'] || dataResponse['full_name']
        },
        commentId: dataResponse.hash,
        content: dataResponse.content,
        lastStatus: dataResponse['last_status'],
        votes: dataResponse['votes_count'] || 0,
        upVotes: dataResponse['up_votes_count'],
        downVotes: dataResponse['down_votes_count'],
        replies: dataResponse['replies'],
        commentedAt: dateFormat(dataResponse['commented_at']),
    }
}

export const GalleryModel = function(dataResponse){
    return {
        id: dataResponse.hash,
        title: dataResponse.title,
        description: dataResponse.description,
        order: dataResponse.order,
        items: (Array.isArray(dataResponse['items'])? dataResponse['items']: Object.values(dataResponse['items'])).map(photo => ({
            id: photo.hash,
            title: photo.title,
            src: resolveImageURL(photo) + '?type=medium',
            caption: photo.caption,
            small: resolveImageURL(photo) + "?type=small",
            thumbnail: resolveImageURL(photo) + "?type=thumbnail",
        }))
    }
}

export const PricesModel = function(dataResponse){
    return {
        id: dataResponse.id,
        name: dataResponse.name,
        order: dataResponse.order,
        website: dataResponse.website
    }
}

export const SinglePriceModel = function(dataResponse){
    return {
        name: dataResponse.name,
        currentDate: dataResponse['current_date'],
        compareDate: dataResponse['compare_date'],
        currentPrice: dataResponse['current_price'],
        comparePrice: dataResponse['compare_price'],
    }
}

export const HierarchyModel = function(dataResponse){
    return dataResponse
}

export const MediaModel = function(dataResponse){
    return dataResponse
}
