import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {GalleryModel} from "../../lib/models/index.js";
import {API_URL} from "../../lib/core-x/Connection.js";

const Galleries = Modeler.use({
    model: GalleryModel,
    url: `${API_URL}galleries`
})

export const FetchGalleriesThunk = createAsyncThunk('galleries/fetch', async function(config = undefined, {getState}){

    let {type} = ( config ?? {} );

    let state = getState();
    let {galleries} = state.gallerySlice;

    if (!type && galleries?.length) {
        return galleries;
    }

    return await Modeler.promisify(Galleries.take, {});

});
