import React from "react";
import {Link} from "react-router-dom";
import DefaultImage from "../../DefaultImage.js";

const PulsePagination = function(){

    return (
        <div className='pb-12 flex flex-row w-full items-center justify-center mx-auto gap-6'>
            <div className="block pulse rounded-md bg-gray-300 h-8 w-8"/>
            <div className="flex flex-row items-center gap-2">
                <span className="pulse anim-delay-1 rounded-full w-6 h-6 block bg-gray-500"/>
                <span className="pulse anim-delay-2 rounded-full w-6 h-6 block bg-gray-500"/>
                <span className="pulse anim-delay-3 rounded-full w-6 h-6 block bg-gray-500"/>
                <span className="pulse anim-delay-4 rounded-full w-6 h-6 block bg-gray-500"/>
            </div>
            <div className="block pulse rounded-md bg-gray-300 h-8 w-8"/>
        </div>
    )
}

export default PulsePagination
