import React, {useEffect, useState} from "react";
import PageTitle from "../components/core/PageTitle.js";
import QuestionCard from "../components/forum/QuestionCard.js";
import Modeler from "../lib/core-x/index.js";
import {QuestionModel} from "../lib/models/index.js";
import {API_URL} from "../lib/core-x/Connection.js";

const Question = Modeler.use({
    model: QuestionModel,
    url: `${API_URL}questions`
});

export default function(){

    const [isLoading, setIsLoading] = useState(true);
    const [questions, setQuestions] = useState([]);

    useEffect(()=>{
        (async function(){
            if(isLoading){
                let questions = await Modeler.promisify(Question.take);
                setQuestions(questions);
                setIsLoading(false);
            }
        })();
    }, [questions])

    return (
        <div className="my-container">
            <PageTitle className="my-6">
                Tanya Jawab
            </PageTitle>
            <div className="flex flex-row gap-2.5">
                <div className="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9333 15.8915C11.9333 16.3998 11.5999 17.0665 11.1749 17.3248L9.99997 18.0831C8.9083 18.7581 7.39163 17.9998 7.39163 16.6498V12.1915C7.39163 11.5998 7.0583 10.8415 6.71663 10.4248L3.51661 7.05813C3.09161 6.63313 2.7583 5.88314 2.7583 5.37481V3.44147C2.7583 2.43313 3.51665 1.6748 4.44165 1.6748H15.5583C16.4833 1.6748 17.2416 2.43313 17.2416 3.35813V5.20813C17.2416 5.88313 16.8166 6.7248 16.4 7.14147" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.3917 13.7669C14.8644 13.7669 16.0583 12.573 16.0583 11.1003C16.0583 9.62751 14.8644 8.43359 13.3917 8.43359C11.9189 8.43359 10.725 9.62751 10.725 11.1003C10.725 12.573 11.9189 13.7669 13.3917 13.7669Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5583 14.2669L15.725 13.4336" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <input className="leading-none flex-grow py-1.5 px-3 focus:outline-none rounded border border-neutral-50 text-neutral-70 " type="text" placeholder="Cari Pertanyaan"/>
                <div className="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.58329 17.5003C13.9555 17.5003 17.5 13.9559 17.5 9.58366C17.5 5.2114 13.9555 1.66699 9.58329 1.66699C5.21104 1.66699 1.66663 5.2114 1.66663 9.58366C1.66663 13.9559 5.21104 17.5003 9.58329 17.5003Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.3333 18.3337L16.6666 16.667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <a className="button">
                    Ajukan Pertanyaan
                </a>
            </div>
            <div className="mt-6 flex flex-col gap-2">
                {questions.map(question => (
                    <QuestionCard
                        title={question.title}
                        slug={question.slug}
                        views={question.views} votes={question.votes}
                        answers={question.commentsCount}
                        category={question.category}
                        lastChange={question.lastStatus}
                        user={question.user || {}}
                    />
                ))}
            </div>
        </div>
    )
}
