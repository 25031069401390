import React, {useEffect} from "react";
import General from "../layouts/General.js";
import PostCard from "../components/posts/PostCard.js";
import {Link} from "react-router-dom";
import {useSelector, useStore} from "react-redux";
import {SelectLoadingRecentPosts, SelectRecentPosts} from "../redux-store/slices/post-slice.js";
import {FetchRecentPostsThunk} from "../redux-store/thunks/post-thunk.js";
import PulseCard from "../components/core/loaders/PulseCard.js";
import FooterLogo from '../assets/footer-logo.png';
import ReactCarousel from "../components/core/carousel/ReactCarousel.js";
import domba from '../assets/domba.png';
import domba2 from '../assets/domba2.png';
import bannerImage from "../assets/banner.png";

export default function Home({page}){

    const store = useStore();
    const recentPosts = useSelector(SelectRecentPosts);
    const isFetching = useSelector(SelectLoadingRecentPosts);

    useEffect(()=>{
        store.dispatch(FetchRecentPostsThunk({}));
    }, []);

    return (
        <div className="text-neutral-100 tracking-tight ">
          <ReactCarousel>
            <div>
              <img src={bannerImage} className={'w-full h-[550px] object-cover inset-0 relative'}/>
            </div>
            <div>
              <img src={bannerImage} className={'w-full h-[550px] object-cover inset-0 relative'}/>
            </div>
          </ReactCarousel>
          <div className={'my-container py-24'}>
            <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
              <img className={'w-64 min-w-[16rem] rounded-lg drop-shadow-2xl'} src={domba}/>
              <div className={'flex flex-col gap-4 items-start 2xl:max-w-5xl max-w-3xl'}>
                <div className={'space-y-2'}>
                  <p className={'text-black text-lg font-medium'}>
                    Peternakan Domba Farm UMKM
                  </p>
                  <h2 className={'text-3xl text-primary font-bold'}>
                    Peternakan Domba Farm UMKM adalah peternakan berbasis website
                  </h2>
                </div>
                <h6 className={'text-lg '}>
                  Kami menyediakan layanan seperti investasi, pemasaran, dan tips untuk peternakan
                </h6>
                <button className={'button'}>
                  Selengkapnya
                </button>
              </div>
            </div>
            <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
              <div className={'flex flex-col gap-4 items-start 2xl:max-w-5xl max-w-3xl'}>
                <div className={'space-y-2'}>
                  <p className={'text-black text-lg font-medium'}>
                    Investasi Domba
                  </p>
                  <h2 className={'text-3xl text-primary font-bold'}>
                    Yuk mulai investasi, dengan investasi di Peternakan Dampit anda akan merasakan manfaat nya
                  </h2>
                </div>
                <h6 className={'text-lg '}>
                  Dapatkan pelatihan,  dan dukungan yang kamu butuhkan untuk membangun bisnis penjualan domba online yang selalu kamu inginkan.
                </h6>
                <button className={'button'}>
                  Mulai Investasi
                </button>
              </div>
              <div className={'relative w-64 min-w-[16rem]'}>
                <span className={'block absolute w-full h-full rounded-lg transform rotate-90 bg-primary'}/>
                <img className={'w-full rounded-lg drop-shadow-2xl'} src={domba}/>
              </div>
            </div>
            <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
              <div className={'relative w-72 min-w-[18rem]'}>
                <span className={'block absolute w-full h-full -left-8 rounded-lg transform rotate-90 bg-primary'}/>
                <img className={'w-full rounded-lg drop-shadow-2xl relative left-4'} src={domba2}/>
              </div>
              <div className={'flex flex-col gap-4 items-start 2xl:max-w-5xl max-w-3xl'}>
                <h2 className={'text-3xl text-primary font-bold'}>
                  Domba Dampit
                </h2>
                <h6 className={'text-lg '}>
                  Domba dampit tersedia di website peternakan dampit  yuk temukan domba untuk aqiqah, qurban di peternakan dampit                </h6>
                <button className={'button'}>
                  Beli Domba
                </button>
              </div>
            </div>
          </div>
        </div>
    )
}
