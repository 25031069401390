import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {API_URL, axiosPost} from "../../lib/core-x/Connection.js";

export default function({id, content, lastChange='', user, commentedAt, upVotes, downVotes, userVotes, isReply=false}){

    const [isVoting, setIsVoting] = useState(false)
    const [sessionVote, setSessionVote] = useState(null)
    const [userVote, setUserVote] = useState({});

    const [upVotesReactive, setUpVotes] = useState(0);
    const [downVotesReactive, setDownVotes] = useState(0);

    useEffect(()=>{
        setUserVote(userVotes.find(vote => vote['comment_id'] === id) || {})
        setUpVotes(upVotes);
        setDownVotes(downVotes);
        setSessionVote(userVote.is_up? 'upvote': !userVote.is_up? 'downvote': null)
    }, [userVotes]);

    const upVote = async function () {
        if (!isVoting) {
            setIsVoting(true);
            const voted = await axiosPost(API_URL + 'vote', {
                'comment_id': id,
                'is_up': true
            });
            if(voted === 'deleted'){
                setSessionVote('deleted');
                setUserVote({});
                setUpVotes(upVotesReactive - 1);
            } else {
                // If is downset
                if(checkIsDown){
                    setDownVotes(downVotesReactive - 1);
                }

                setSessionVote('upvote');
                setUserVote({
                    is_up: true,
                });
                setUpVotes(upVotesReactive + 1);
            }
            setIsVoting(false);
        }
    }

    const downVote = async function() {
        if (!isVoting){
            setIsVoting(true);
            const voted = await axiosPost(API_URL + 'vote', {
                'comment_id': id,
                'is_up': false
            });
            if(voted === 'deleted'){
                setSessionVote('deleted');
                setUserVote({});
                setDownVotes(downVotesReactive - 1);
            } else {
                // If is upvote
                if(checkIsUp){
                    setUpVotes(upVotesReactive - 1);
                }

                setSessionVote('downvote');
                setUserVote({
                    is_up: false,
                });
                setDownVotes(downVotesReactive + 1);
            }
            setIsVoting(false);
        }
    }

    const userVoteUp = useMemo(()=>{
        return userVote && userVote['is_up']
    }, [userVote]);

    const userVoteDown = useMemo(()=>{
        return userVote && !userVote['is_up']
    }, [userVote]);

    const checkIsDown = useMemo(()=>{
        return userVoteDown && (sessionVote === 'downvote')
    }, [userVoteDown, sessionVote]);

    const checkIsUp = useMemo(()=>{
        return userVoteUp && (sessionVote === 'upvote')
    }, [userVoteUp, sessionVote]);


    return (
        <div className="flex flex-row gap-4 items-start">
            {user?.image? (
                <Link to={'/pengguna/' + user?.username}>
                    <img src={user.image} className="w-11 h-11 object-cover rounded-md"/>
                </Link>
            ): (
                <div className="bg-neutral-30 border border-neutral-40 rounded-md h-fit w-fit p-3">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.1667 5.83366C14.1667 7.8587 12.5251 9.50033 10.5 9.50033C8.475 9.50033 6.83337 7.8587 6.83337 5.83366C6.83337 3.80861 8.475 2.16699 10.5 2.16699C12.5251 2.16699 14.1667 3.80861 14.1667 5.83366Z" fill="#A2A6AD" stroke="#A2A6AD"/>
                        <path d="M17.5743 18.2497H3.4258C3.48063 15.1973 6.54262 12.583 10.5 12.583C14.4575 12.583 17.5195 15.1973 17.5743 18.2497Z" fill="#A2A6AD" stroke="#A2A6AD"/>
                    </svg>
                </div>
            )}
            <div className="flex-grow flex flex-col gap-4">
                <div className="flex flex-col gap-4 p-4 bg-white rounded-md">
                    <p className="text-m-regular text-neutral-100">
                        {user?.fullName} {user?.username? '': '(Anonim)'} | {commentedAt}
                    </p>
                    <span className="side-splitter-2"/>
                    <p className="text-m-regular text-neutral-90">
                        {content}
                    </p>
                    <div className="flex flex-row items-center gap-1.5">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6666 8.49967C14.6666 12.1797 11.6799 15.1663 7.99992 15.1663C4.31992 15.1663 1.33325 12.1797 1.33325 8.49967C1.33325 4.81967 4.31992 1.83301 7.99992 1.83301C11.6799 1.83301 14.6666 4.81967 14.6666 8.49967Z" stroke="#616976" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.4734 10.6202L8.40675 9.38684C8.04675 9.1735 7.75342 8.66017 7.75342 8.24017V5.50684" stroke="#616976" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <p className="text-s-regular">
                            {lastChange}
                        </p>
                    </div>
                </div>
                <a className="button ml-auto cursor-pointer">
                    {isReply? 'Reply': 'Add a Comment'}
                </a>
            </div>
            <div className={"flex flex-col gap-4 " + (isVoting? 'opacity-25': '')}>
                <div onClick={upVote} className='cursor-pointer flex flex-row items-center gap-2 text-primary'>
                    {checkIsUp?(
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8748 3.42873L11.8748 3.42874L11.8769 3.4256C12.0219 3.20647 12.3004 2.98901 12.6515 2.8649C12.9983 2.74234 13.3703 2.72683 13.6949 2.84811L13.7026 2.85097L13.7103 2.85359C14.4626 3.1069 14.9549 3.96685 14.801 4.68497L14.7982 4.69802L14.7961 4.71121L14.2761 7.98121L14.2759 7.98118L14.2743 7.99365C14.2166 8.42659 14.3328 8.835 14.5913 9.15433L14.599 9.16393L14.6073 9.17313C14.8679 9.46443 15.2514 9.64973 15.6699 9.64973H19.7799C20.4351 9.64973 20.9506 9.91201 21.242 10.3189C21.5132 10.7054 21.5835 11.2307 21.3783 11.8137L21.3782 11.8136L21.3749 11.8237L18.9149 19.3137L18.9092 19.3309L18.9048 19.3485C18.6545 20.3499 17.5174 21.2197 16.3899 21.2197H12.4899C12.2056 21.2197 11.8512 21.1699 11.523 21.0719C11.1844 20.9708 10.9335 20.8362 10.8034 20.7062L10.781 20.6837L10.7558 20.6642L9.47579 19.6742L9.47581 19.6742L9.47119 19.6707C9.10865 19.397 8.88989 18.9559 8.88989 18.4897V8.32973C8.88989 8.02994 8.97982 7.73656 9.14539 7.48788C9.14556 7.48761 9.14574 7.48735 9.14592 7.48708L11.8748 3.42873Z" fill="#00A884" stroke="#00A884"/>
                            <path d="M4.18 6.87988H5.21C5.93877 6.87988 6.32401 7.02437 6.53617 7.22671C6.74247 7.42346 6.89 7.7763 6.89 8.45988V18.5199C6.89 19.2035 6.74247 19.5563 6.53617 19.7531C6.32401 19.9554 5.93877 20.0999 5.21 20.0999H4.18C3.45123 20.0999 3.06599 19.9554 2.85383 19.7531C2.64753 19.5563 2.5 19.2035 2.5 18.5199V8.45988C2.5 7.7763 2.64753 7.42346 2.85383 7.22671C3.06599 7.02437 3.45123 6.87988 4.18 6.87988Z" fill="#00A884" stroke="#00A884"/>
                        </svg>
                    ): (
                        <svg className="transform rotate-180" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.52 5.65039L13.42 3.25039C13.02 2.85039 12.12 2.65039 11.52 2.65039H7.71998C6.51998 2.65039 5.21998 3.55039 4.91998 4.75039L2.51998 12.0504C2.01998 13.4504 2.91998 14.6504 4.41998 14.6504H8.41998C9.01998 14.6504 9.51998 15.1504 9.41998 15.8504L8.91998 19.0504C8.71998 19.9504 9.31998 20.9504 10.22 21.2504C11.02 21.5504 12.02 21.1504 12.42 20.5504L16.52 14.4504" stroke="#00A884" strokeWidth="1.5" strokeMiterlimit="10"/>
                            <path d="M21.6199 5.65V15.45C21.6199 16.85 21.0199 17.35 19.6199 17.35H18.6199C17.2199 17.35 16.6199 16.85 16.6199 15.45V5.65C16.6199 4.25 17.2199 3.75 18.6199 3.75H19.6199C21.0199 3.75 21.6199 4.25 21.6199 5.65Z" stroke="#00A884" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    )}
                    <a>
                        {upVotesReactive}
                    </a>
                </div>
                <div onClick={downVote} className='cursor-pointer flex flex-row items-center gap-2 text-secondary-text'>
                    {(checkIsDown)?(
                        <svg className="transform rotate-180" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8748 3.42873L11.8748 3.42874L11.8769 3.4256C12.0219 3.20647 12.3004 2.98901 12.6515 2.8649C12.9983 2.74234 13.3703 2.72683 13.6949 2.84811L13.7026 2.85097L13.7103 2.85359C14.4626 3.1069 14.9549 3.96685 14.801 4.68497L14.7982 4.69802L14.7961 4.71121L14.2761 7.98121L14.2759 7.98118L14.2743 7.99365C14.2166 8.42659 14.3328 8.835 14.5913 9.15433L14.599 9.16393L14.6073 9.17313C14.8679 9.46443 15.2514 9.64973 15.6699 9.64973H19.7799C20.4351 9.64973 20.9506 9.91201 21.242 10.3189C21.5132 10.7054 21.5835 11.2307 21.3783 11.8137L21.3782 11.8136L21.3749 11.8237L18.9149 19.3137L18.9092 19.3309L18.9048 19.3485C18.6545 20.3499 17.5174 21.2197 16.3899 21.2197H12.4899C12.2056 21.2197 11.8512 21.1699 11.523 21.0719C11.1844 20.9708 10.9335 20.8362 10.8034 20.7062L10.781 20.6837L10.7558 20.6642L9.47579 19.6742L9.47581 19.6742L9.47119 19.6707C9.10865 19.397 8.88989 18.9559 8.88989 18.4897V8.32973C8.88989 8.02994 8.97982 7.73656 9.14539 7.48788C9.14556 7.48761 9.14574 7.48735 9.14592 7.48708L11.8748 3.42873Z" fill="#FF844B" stroke="#FF844B"/>
                            <path d="M4.18 6.87988H5.21C5.93877 6.87988 6.32401 7.02437 6.53617 7.22671C6.74247 7.42346 6.89 7.7763 6.89 8.45988V18.5199C6.89 19.2035 6.74247 19.5563 6.53617 19.7531C6.32401 19.9554 5.93877 20.0999 5.21 20.0999H4.18C3.45123 20.0999 3.06599 19.9554 2.85383 19.7531C2.64753 19.5563 2.5 19.2035 2.5 18.5199V8.45988C2.5 7.7763 2.64753 7.42346 2.85383 7.22671C3.06599 7.02437 3.45123 6.87988 4.18 6.87988Z" fill="#FF844B"  stroke="#FF844B"/>
                        </svg>
                    ): (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.52 5.65039L13.42 3.25039C13.02 2.85039 12.12 2.65039 11.52 2.65039H7.71998C6.51998 2.65039 5.21998 3.55039 4.91998 4.75039L2.51998 12.0504C2.01998 13.4504 2.91998 14.6504 4.41998 14.6504H8.41998C9.01998 14.6504 9.51998 15.1504 9.41998 15.8504L8.91998 19.0504C8.71998 19.9504 9.31998 20.9504 10.22 21.2504C11.02 21.5504 12.02 21.1504 12.42 20.5504L16.52 14.4504" stroke="#FF844B" strokeWidth="1.5" strokeMiterlimit="10"/>
                            <path d="M21.6199 5.65V15.45C21.6199 16.85 21.0199 17.35 19.6199 17.35H18.6199C17.2199 17.35 16.6199 16.85 16.6199 15.45V5.65C16.6199 4.25 17.2199 3.75 18.6199 3.75H19.6199C21.0199 3.75 21.6199 4.25 21.6199 5.65Z" stroke="#FF844B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    )}
                    <a>
                        {downVotesReactive}
                    </a>
                </div>
            </div>
        </div>
    )
}
