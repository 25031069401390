import React from 'react';
import Banner from "../layouts/Banner.js";
import banner from "../assets/banner.png";
import domba from "../assets/domba.png";
import kandang from "../assets/oyifarm/kandang.png";
import ternakDomba from "../assets/oyifarm/ternak-domba.png";
import pakan from "../assets/oyifarm/pakan.png";
import penyakit from "../assets/oyifarm/penyakit.png";
import {Link} from "react-router-dom";

export default function AyoTernak({}){
  return (
    <div>
      <Banner title={"AyoTernak"}>
        Temukan info menarik seputar tips untuk peternakan domba mulai dari peluang ternak domba, kandang, pakan dan lain-lain
      </Banner>
      <div className={'my-container py-24'}>
        <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
          <div className={'relative w-64 min-w-[16rem]'}>
            <span className={'block absolute w-44 h-[120%] rounded-lg transform -top-[10%] -left-[25%] bg-primary-image'}/>
            <img className={'w-full rounded-lg drop-shadow-2xl'} src={ternakDomba}/>
          </div>
          <div className={'flex flex-col gap-4 items-center text-center 2xl:max-w-5xl max-w-3xl'}>
            <h2 className={'text-3xl text-primary font-bold'}>
              Peluang Ternak Domba
            </h2>
            <h6 className={'text-lg '}>
              Salah satu jenis ternak yang dapat dibiakkan sekaligus menjadi bisnis yang menjanjikan di Indonesia adalah domba. Usaha ternak domba dapat dijadikan pilihan agribisnis untuk meningkatkan pemasukan Anda.            </h6>
            <Link to={'/oyiFarm/peluang-ternak-domba'} className={'button'}>
              Baca Selengkapnya
            </Link>
          </div>
        </div>
        <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
          <div className={'flex flex-col gap-4 items-center text-center 2xl:max-w-5xl max-w-3xl'}>
            <h2 className={'text-3xl text-primary font-bold'}>
              Kandang
            </h2>
            <h6 className={'text-lg'}>
              Salah satu kendala dalam beternak domba adalah bau kandang yang dapat mencemari lingkungan sekitar. Untuk itu, perlu adanya manajemen kandang yang baik untuk meminimalisir. Selain itu, perencanaan dan manajemen bisnis sangat penting untuk menunjang keberhasilan bisnis ternak domba.
            </h6>
            <Link to={'/oyiFarm/kandang'} className={'button'}>
              Baca Selengkapnya
            </Link>
          </div>
          <div className={'relative w-64 min-w-[16rem]'}>
            <span className={'block absolute w-44 h-[120%] rounded-lg transform -top-[10%] -right-[25%] bg-primary-image'}/>
            <img className={'w-full rounded-lg drop-shadow-2xl'} src={kandang}/>
          </div>
        </div>
        <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
          <div className={'relative w-64 min-w-[16rem]'}>
            <span className={'block absolute w-44 h-[120%] rounded-lg transform -top-[10%] -left-[25%] bg-primary-image'}/>
            <img className={'w-full rounded-lg drop-shadow-2xl'} src={pakan}/>
          </div>
          <div className={'flex flex-col gap-4 items-center text-center 2xl:max-w-5xl max-w-3xl'}>
            <h2 className={'text-3xl text-primary font-bold'}>
              Pakan
            </h2>
            <h6 className={'text-lg '}>
              Pemberian pakan hijauan perlu diperhatikan imbangan antara rumput dan daun leguminosa dikaitkan dengan kondisi fisiologis ternak.
            </h6>
            <Link to={'/oyiFarm/pakan'} className={'button'}>
              Baca Selengkapnya
            </Link>
          </div>
        </div>
        <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
          <div className={'flex flex-col gap-4 items-center text-center 2xl:max-w-5xl max-w-3xl'}>
            <h2 className={'text-3xl text-primary font-bold'}>
              Penyakit
            </h2>
            <h6 className={'text-lg '}>
              Salah satu kendala dalam beternak domba adalah bau kandang yang dapat mencemari lingkungan sekitar. Untuk itu, perlu adanya manajemen kandang yang baik untuk meminimalisir. Selain itu, perencanaan dan manajemen bisnis sangat penting untuk menunjang keberhasilan bisnis ternak domba.
            </h6>
            <Link to={'/oyiFarm/penyakit'} className={'button'}>
              Baca Selengkapnya
            </Link>
          </div>
          <div className={'relative w-64 min-w-[16rem]'}>
            <span className={'block absolute w-44 h-[120%] rounded-lg transform -top-[10%] -right-[25%] bg-primary-image'}/>
            <img className={'w-full rounded-lg drop-shadow-2xl'} src={penyakit}/>
          </div>
        </div>
      </div>
    </div>
  )
}
