import {createSlice} from "@reduxjs/toolkit";
import {FetchCategoriesThunk} from "../thunks/category-thunk.js";

const categorySlice = createSlice({
    name: "categorySlice",
    initialState: {
        categories: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(FetchCategoriesThunk.fulfilled, function(state, action){
           state.categories = action.payload;
        });
    }
});

export const SelectCategories = function(state){
    return state.categorySlice.categories;
};

export const SelectCategoryBySlug = function (slug) {
    return function(state){
        return state.categorySlice.categories.find(c => c.slug === slug);
    }
}

export const SelectCategoryById = function(id){
    return function(state){
        return state.categorySlice.categories.find(c => c.id === id);
    }
}

export default categorySlice.reducer;
