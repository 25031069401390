import React, {useEffect, useRef, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import NavItem from "./NavItem.js";
import {type} from "@testing-library/user-event/dist/type/index.js";
import {getCLS} from "web-vitals";

export default function({onClick, children}){

    const ref = useRef();
    const uniqueId = Math.random().toString(16).slice(2)

    const handleClickOutside = (e) => {
        let path = e.path || (e.composedPath && e.composedPath());
        let hasPath = path.some(a=>a.getAttribute?.('id') === ref.current.getAttribute('id'));
        if (onClick && !hasPath) {
            onClick();
        }
    };

    useEffect(() => {
        if (!ref?.current) {
            return;
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClick]);

    return (
        <>{React.cloneElement(children, { ref , id: uniqueId})}</>
    )
}
