import React, {useEffect, useRef, useState} from "react";

export default function Youtube({url}){

  let ref = useRef();
  let [mounted, setMounted] = useState(false);
  let aspectRatio = 0.5625;

  let getURL = function(){
    let embedURL = 'https://www.youtube.com/embed/';
    let id = typeof url === 'string'? url: '';
    let fragments = id.split('/');
    let last = fragments.length > 1? fragments[fragments.length - 1] :'';
    last = last.replace('watch?v=', '');
    return embedURL + last;
  }

  const updateSize = function(){
    let parent = ref.current.parentNode;
    let {width} = parent.getBoundingClientRect();
    if(`${width}` !== `${ref.current.getAttribute('width')}`) {
      ref.current.setAttribute('width', width);
      ref.current.setAttribute('height', width * aspectRatio);
    }
  }

  useEffect(()=>{
    if(!mounted){
      updateSize();
      window.addEventListener('resize', updateSize);
      setMounted(true);
    }
    return ()=>{
      window.removeEventListener('resize', updateSize);
      setMounted(false);
    }
  }, []);

  return (
    <iframe
      width={640}
      height={360}
      ref={ref}
      src={getURL()}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
