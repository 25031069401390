import {API_URL, BASE_URL, fetchFromAPI} from "./core-x/Connection.js";

export const dateFormat = function(date){
    if(!(date instanceof Date)){
        if(!Date.parse(date)){
            return '';
        }
        date = new Date(date);
    }
    let i18nMonths = ['Januari', 'Febuari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    return [date.getDate(), i18nMonths[date.getMonth()], date.getFullYear()].join(' ');
}

export const excerpt = function(text, length=40){
    return text.length > length? text.substr(0, length) + '...': text;
}

export const groupRegex = function(pattern){
    return new RegExp(pattern, 'g')
}

export const exportHierarchy = function(data=''){
    const regex = /<HIERARCHY>[\s\S]*?<\/HIERARCHY>/i;

    let results = data.match(regex);
    results = results? results[0]: '[]';
    results = results
        .replace(groupRegex('<HIERARCHY>'), '')
        .replace(groupRegex('</HIERARCHY>'), '')

    let hierarchy = JSON.parse(results).sort((a, b) => parseInt(a.lv) - parseInt(b.lv));
    let leveledData = {};

    hierarchy.forEach((item)=>{
        if(!leveledData[item.lv]){
            leveledData[item.lv] = [];
        }
        const {i: image, n: name, p: position} = item;
        leveledData[item.lv].push({name, position, image});
    });


    return {content: data.replace(groupRegex('<HIERARCHY>.*</HIERARCHY>'), ''), hierarchy: leveledData};
}

export const debounce = function(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    }
}

export const slashJoin = function(path){
    return path.replace(/([\\][\\])/g, '\\');
}

export const getSystemFile = function(path){
    return slashJoin(BASE_URL + path);
}

export const extractMap = function(data, pattern = '$', defaults = ''){

    if(typeof pattern !== "string"){
        return defaults;
    }

    if(!pattern.startsWith('$')){
        return pattern;
    }

    let patterns = pattern.split('\.').slice(1);

    return patterns.reduce((prev, curr) => {

        if(prev === null || prev === undefined){
            return prev;
        }

        // If is Array and have certain value filter
        if(curr.match(/\{.*\}/)){
            // {matched=1} => matched=1
            let internal = curr.substring(1, curr.length - 1).split('\=');

            let key = internal[0];
            let value = internal[1];

            return prev.find(p => p[key] + '' === value + '');
        }


        // If is Array and have certain index
        if(curr.match(/\[.*\]/)){
            // [0] => 0
            let index = curr.substring(1, curr.length - 1);
            return prev[index];
        }

        return prev[curr];

    }, data);


}

export const extractFromApi = function(url, patterns = {}){
    return fetchFromAPI(url).then(result => {

        return Object.keys(patterns).reduce((prev, key)=>{
            return {
                ...prev,
                [key]: extractMap(result, patterns[key], '')
            };
        }, {})

    });
}

export const monetize = function(amount){
    return `Rp. ${amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') || '-'}`;
}

export const resolveURL = function(url){
    if(!url || typeof url !== 'string'){
        return '';
    }
    if(url?.startsWith('http')){
        return url;
    }
    return (BASE_URL + url).replace(/\/\//g, '/')
        .replace('http:/', 'http://')
        .replace('https:/', 'https://')
}

export const resolveImageURL = function(image){
    let hash = '';
    if(typeof image === 'object'){
        hash = image.hash;
    } else if(typeof image === 'string'){
        hash = image;
    }
    if(!hash.length)
        return '';
    return (API_URL + 'docs/' + hash)
}

export const siteURL = function(){
    return "http://localhost:3000";
}

export const shareLink = function(title, link, type = null){
    if(!type){
        navigator.clipboard.writeText(link);
        return '';
    }
    let map = {
        line: function (t, l) {
            return 'https://social-plugins.line.me/lineit/share?url=' + encodeURI(l);
        },
        whatsapp: function (t, l) {
            return 'https://api.whatsapp.com/send?text=' + encodeURI(t + '\n' + l);
        },
        twitter: function (t, l) {
            return 'https://twitter.com/intent/tweet?text=' + encodeURI(t + ' via ' + l);
        },
        facebook: function (t, l) {
            return 'https://www.facebook.com/sharer.php?u=' + encodeURI(l);
        },
        telegram: function (t, l) {
            return 'https://telegram.me/share/url?url=' + encodeURI(t) + '&text=' + encodeURI(l);
        }
    }
    return map[type]?.(title, link);
    // window.open(sharedLink, '_blank');
}



// Content => type: hierarchy, content: c
export const extractContents = function(content = ''){

    content = content.replace(new RegExp('\n', 'g'), '[linebreak=1]');
    let registeredShortcodes = ['media', 'hierarchy', 'document', 'youtube', 'linebreak'];
    let regex = registeredShortcodes.map(a => '(\\[' + a + '([^\\]])+\\])').join('|');

    let matches = content.matchAll(new RegExp('(' + regex + ')', 'g'));
    let results = [...matches];

    let contents = [];
    let sortedIndices = results.map(r => ({
        index: r.index,
        length: r[0].length,
        matched: r[0],
        value: r[0].substring(1, r[0].length - 1).split('=')[1] ?? null,
        key: r[0].substring(1, r[0].length - 1).split('=')[0]
    })).sort((a, b) => a.index - b.index);

    let i = 0;
    sortedIndices.forEach(({index, length, key, value}) => {
       let c = content.substring(i, index);
        // Pushes the content from unmatched index of offset
        if(c !== ''){
           contents.push({
               type: 'content',
               value: c
           })
       }
        // Pushes the matched types
       contents.push({
           type: key,
           value: value,
       })
        i = index + length;
    });

    // Pushes the rest of the content
    if(i !== content.length - 1){
        contents.push({
            type: 'content',
            value: content.substring(i)
        })
    }

    return contents;
}
