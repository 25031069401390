import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {CountModel, PostModel} from "../../lib/models/index.js";
import {API_URL, axiosGet, BASE_URL} from "../../lib/core-x/Connection.js";

const Posts = Modeler.use({
    model: PostModel,
    url: `${API_URL}posts`
})

const Single = Modeler.use({
    model: PostModel,
    url: `${API_URL}posts/`
})

const Counter = Modeler.use({
    model: CountModel,
    url: `${API_URL}posts`
})

export const FetchPostsThunk = createAsyncThunk('posts/fetch', async function({page, limit, category}, {getState, extra}){

    let state = getState();
    let {posts, fulfilled} = state.postSlice;

    return await (new Promise((resolve, reject) => {

        if(fulfilled.some(f => f.page === page && f.limit === limit && f.category === category)){

            let selectedPosts = posts.filter(post => {
                let info = post.fetchInfo ?? [];
                return info.page === page && info.limit === limit && info.category === category;
            });

            resolve({
                posts: selectedPosts,
                fulfilled: {page, limit},
                category,
            });
            return;

        }

        Modeler.promisify(Posts.take, {page, limit, category: category? category: null}).then(posts => {
            let selectedPosts = posts.map((post, index) => ({
                ...post,
                fetchInfo: {
                    limit,
                    page,
                    category,
                }
            }))

            resolve({
                posts: selectedPosts,
                fulfilled: {page, limit, category: category}
            });
        });

    }));

});

export const FetchPostCountThunk = createAsyncThunk('posts/fetchCount', async function({category}, {getState}){
    let {postSlice} = getState();
    let {currentCount} = postSlice;

    if(!('total' in currentCount && currentCount.category === category)){
        return {
            ...(await Modeler.promisify(Counter.take, {isCount: true, category: category? category: null})),
            category,
        };
    }

    return currentCount;
})

export const FetchRecentPostsThunk = createAsyncThunk('posts/recent', async function({} = undefined, {getState}){
    let {postSlice} = getState();
    let {recentPosts, isFetchingRecentPostsLoading} = postSlice;

    if(!isFetchingRecentPostsLoading)
        throw "";
    if(!recentPosts){
        return await Modeler.promisify(Posts.take, {limit: 5, page: 1});
    }

    return null;
});

export const FetchPostBySlug = createAsyncThunk('posts/fetchBySlug', async function({slug}, {getState}){
    let state = getState();
    let {posts} = state.postSlice;
    let post = posts.find(p => p.slug === slug);

    if(!post){
        post = await Modeler.promisify(Single.get, slug);
        // post = PostModel(await axiosGet(BASE_URL + 'api/v1/posts/' + slug));
    }

    let extensiblePost = {
        ...post
    }

    // Get related post if not exists
    if(!extensiblePost.relatedPosts){
        extensiblePost.relatedPosts = await Modeler.promisify(Posts.take, {limit: 3, slug: post.slug});
    }

    return extensiblePost;
});
