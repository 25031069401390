import React from "react";
import {Link} from "react-router-dom";

export default function({views, category, lastChange, asDate=false}){
    return (
        <div className="flex flex-row gap-4 text-s-regular text-neutral-90">
            <div className="flex items-center gap-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z" stroke="#616976" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.00002 13.5138C10.3534 13.5138 12.5467 12.1271 14.0734 9.72714C14.6734 8.78714 14.6734 7.20714 14.0734 6.26714C12.5467 3.86714 10.3534 2.48047 8.00002 2.48047C5.64668 2.48047 3.45335 3.86714 1.92668 6.26714C1.32668 7.20714 1.32668 8.78714 1.92668 9.72714C3.45335 12.1271 5.64668 13.5138 8.00002 13.5138Z" stroke="#616976" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p>{views} views</p>
            </div>
            <div className="flex items-center gap-2">
                {asDate? (
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.33325 1.83301V3.83301" stroke="#616976" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.6667 1.83301V3.83301" stroke="#616976" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M2.33325 6.55957H13.6666" stroke="#616976" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 6.16634V11.833C14 13.833 13 15.1663 10.6667 15.1663H5.33333C3 15.1663 2 13.833 2 11.833V6.16634C2 4.16634 3 2.83301 5.33333 2.83301H10.6667C13 2.83301 14 4.16634 14 6.16634Z" stroke="#616976" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.4632 9.63314H10.4692" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.4632 11.6331H10.4692" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.99691 9.63314H8.0029" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.99691 11.6331H8.0029" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.52962 9.63314H5.53561" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.52962 11.6331H5.53561" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                ):(
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.66671 12.6663H5.33337C2.66671 12.6663 1.33337 11.9997 1.33337 8.66634V5.33301C1.33337 2.66634 2.66671 1.33301 5.33337 1.33301H10.6667C13.3334 1.33301 14.6667 2.66634 14.6667 5.33301V8.66634C14.6667 11.333 13.3334 12.6663 10.6667 12.6663H10.3334C10.1267 12.6663 9.92671 12.7663 9.80004 12.933L8.80004 14.2663C8.36004 14.853 7.64004 14.853 7.20004 14.2663L6.20004 12.933C6.09337 12.7863 5.84671 12.6663 5.66671 12.6663Z" stroke="#616976" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.6643 7.33333H10.6703" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.99703 7.33333H8.00302" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.32967 7.33333H5.33566" stroke="#616976" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                )}
                <p>{lastChange}</p>
            </div>
            <Link to={`/forum/kategori/${category.slug}`} className="flex items-center gap-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.6667 7.33301V11.333C14.6667 13.9997 14 14.6663 11.3334 14.6663H4.66671C2.00004 14.6663 1.33337 13.9997 1.33337 11.333V4.66634C1.33337 1.99967 2.00004 1.33301 4.66671 1.33301H5.66671C6.66671 1.33301 6.88671 1.62634 7.26671 2.13301L8.26671 3.46634C8.52004 3.79967 8.66671 3.99967 9.33337 3.99967H11.3334C14 3.99967 14.6667 4.66634 14.6667 7.33301Z" stroke="#616976" strokeWidth="1.5" strokeMiterlimit="10"/>
                </svg>
                <p>Kategori {category.name}</p>
            </Link>
        </div>
    )
}
