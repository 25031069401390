import {createSlice} from "@reduxjs/toolkit";
import {FetchGalleriesThunk} from "../thunks/gallery-thunk.js";

const gallerySlice = createSlice({
    name: "gallerySlice",
    initialState: {
        galleries: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(FetchGalleriesThunk.fulfilled, function(state, action){
           state.galleries = action.payload;
        });
    }
});

export const SelectGalleries = function(state){
    return state.gallerySlice.galleries;
};

export const SelectGalleryById = function(id){
    return function(state){
        return state.gallerySlice.galleries.find(c => c.hash === id);
    }
}

export default gallerySlice.reducer;
