import Modeler from "./lib/core-x/index.js";
import {BlogModel, CategoryModel, MenuModel, PageModel} from "./lib/models/index.js";
import routers from "./routers/index.js";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {API_URL} from "./lib/core-x/Connection.js";
import {useDispatch, useSelector, useStore} from "react-redux";
import {FetchMenusThunk} from "./redux-store/thunks/menu-thunk.js";
import {SelectMenus} from "./redux-store/slices/menu-slice.js";
import {FetchCategoriesThunk} from "./redux-store/thunks/category-thunk.js";

const Menu = Modeler.use({
    model: MenuModel,
    url: API_URL + 'menus',
})
const Category = Modeler.use({
    model: CategoryModel,
    url: API_URL + 'categories',
})

export default function App() {

    const [categories, setCategories] = useState([])
    const [isMounted, setIsMounted] = useState(true)
    const store = useStore();

    useEffect(()=>{

        store.dispatch(FetchMenusThunk());
        store.dispatch(FetchCategoriesThunk());

    }, [categories]);

    const getObject = function(name){
        return null
    }


    const getRoute = function(route, index){
        let props = route.props;
        let children = route.children || [];
        let obj = {};
        if(props){
            for(let i of props){
                obj[i] = getObject(i);
            }
        }
        return (
            <Route
                key={index}
                path={`${route.path}`}
                element={React.cloneElement(route.component, obj)}
            >
                {children.map(getRoute)}
            </Route>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                {routers.map(getRoute)}
            </Routes>
        </BrowserRouter>
    )
}
