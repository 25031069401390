import React, {useMemo} from "react";
import DefaultImage from "../../components/DefaultImage.js";
import PageTitle from "../../components/core/PageTitle.js";
import {extractContents} from "../../lib/functions.js";
import Hierarchy from "./Hierarchy.js";
import Media from "./Media.js";
import Youtube from "./Youtube.js";
import { Remarkable } from 'remarkable';
import classNames from "classnames";

export default function ({data, isPage}) {

    const {title, content, image} = data;

    const extracted = useMemo(()=>{
        return extractContents(content);
    }, [content]);
    return (
        <>
          {title? (
            <PageTitle>
              {title}
            </PageTitle>
            )
            : ''}
            {image? (
                <img className='w-full my-6' src={image}/>
            ): ''}
            <div className={classNames('leading-6 text-[#756348]', {'mt-6': isPage})}>
                {extracted.map(({type, value}, i) => (
                    <React.Fragment key={i}>
                        {type === 'linebreak'? <span className={'markdown-break'}/>: ''}
                        {type === 'content'? <RealMarkdown content={value}/>: ''}
                        {type === 'hierarchy'? <Hierarchy id={value}/>: ''}
                        {type === 'media'? <Media className={'rounded-xl'} hash={value}/>: ''}
                        {type === 'youtube'? <Youtube url={value}/>: ''}
                    </React.Fragment>
                ))}
            </div>
        </>
    )
}

const RealMarkdown = function({content}){

  let md = new Remarkable({
    html:         true,
    xhtmlOut:     true,
    breaks:       false,
    typographer:  false,
    quotes: '“”‘’',
    highlight: function (/*str, lang*/) { return ''; }
  });

  return (
      <div className={'markdown'} dangerouslySetInnerHTML={{__html: md.render(content)}}/>
  )
}
