import React, {useEffect, useState} from "react";
import GalleryGroup from "../layouts/GalleryGroup.js";
import {useDispatch, useSelector} from "react-redux";
import {FetchGalleriesThunk} from "../redux-store/thunks/gallery-thunk.js";
import {SelectGalleries} from "../redux-store/slices/gallery-slice.js";

export default function (){

    const galleries = useSelector(SelectGalleries);
    const dispatch = useDispatch();
    const [isMounted, setMounted] = useState(false);

    let normal = 'lg:w-1/2';
    let defaultClass = 'lg:w-full';

    useEffect(()=>{
        if(!isMounted){
            dispatch(FetchGalleriesThunk());
            setMounted(true);
        }
    }, [isMounted]);

    return (
        <div className="my-container flex flex-col gap-4 pt-4 pb-40 text-neutral-90 text-l-regular">
            <p className="text-heading-1">
                Galeri
            </p>
            <div className={'flex flex-col lg:flex-row flex-wrap -mx-6 gap-y-6'}>
                {(galleries ?? []).map(gallery => (
                    <div key={gallery.id} className={(galleries?.length > 1? normal: defaultClass) + ' px-6'}>
                        <GalleryGroup items={gallery.items} title={gallery.title} description={gallery.description}/>
                    </div>
                ))}
            </div>
        </div>
    )
}
