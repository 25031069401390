import React, {useEffect, useMemo, useState} from "react";
import DefaultImage from "../components/DefaultImage.js";
import SideItem from "../components/Sidebar/SideItem.js";
import {useLocation} from "react-router-dom";
import Modeler from "../lib/core-x/index.js";
import {PageModel} from "../lib/models/index.js";
import Collapsible from "../components/Collapsible.js";
import BaseAlert from "../components/core/alerts/BaseAlert.js";

export default function({}){

    const documents = [
        {
            label: 'Dasar dan Landasan',
            items: [
                {
                    label: 'Item 1',
                    link: '',
                },
                {
                    label: 'Item 2',
                    link: '',
                },
                {
                    label: 'Item 3',
                    link: '',
                },
                {
                    label: 'Item 4',
                    link: '',
                }
            ]
        },
        {
            label: 'Pedoman',
            items: [
                {
                    label: 'Item 1',
                    link: '',
                },
                {
                    label: 'Item 2',
                    link: '',
                },
                {
                    label: 'Item 3',
                    link: '',
                },
            ]
        },
        {
            label: 'Lain-lain',
            items: [
                {
                    label: 'Item 1',
                    link: '',
                },
                {
                    label: 'Item 2',
                    link: '',
                },
            ]
        }
    ]

    return (
        <div className="my-container flex flex-col gap-4 pt-4 pb-16 text-neutral-90 text-l-regular">
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut euismod ultricies interdum arcu sit integer pharetra eget nibh. Ipsum sed egestas eget elementum mauris ac. Quis sit at duis blandit tristique. Dignissim non senectus mattis enim ultrices ut cum feugiat. Praesent et vulputate magna sagittis sed fringilla amet. Proin diam est facilisis nibh dui. Viverra ut sit integer mi, turpis non justo elementum adipiscing. Consequat in amet cras fermentum enim tellus. Nunc urna elementum eu, at egestas.
            </div>
            <div>
                {documents.map(doc => (
                    <Collapsible text={doc.label}>
                        {doc.items.map((item, index) => (
                            <div className={"text-neutral-90 p-2 flex justify-between w-full items-center " + (index % 2 === 0? 'bg-primary-surface': 'bg-primary-dropdown-pressed')}>
                                <a>{item.label}</a>
                                <a className="cursor-pointer text-primary-main w-40">Unduh</a>
                            </div>
                        ))}
                    </Collapsible>
                ))}
            </div>
            <BaseAlert>
                Dokumen peraturan ini akan terus dilakukan pembaharuan sesuai dengan perkembangan yang ada.
            </BaseAlert>
        </div>
    )
}
