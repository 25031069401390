import React, {useEffect, useMemo, useState} from "react";
import PageTitle from "../components/core/PageTitle.js";
import QuestionCard from "../components/forum/QuestionCard.js";
import Modeler from "../lib/core-x/index.js";
import {QuestionModel, Comment} from "../lib/models/index.js";
import {API_URL} from "../lib/core-x/Connection.js";
import {useParams} from "react-router-dom";
import QuestionInfo from "../components/forum/QuestionInfo.js";
import CommentCard from "../components/forum/CommentCard.js";

const Question = Modeler.use({
    model: QuestionModel,
    url: `${API_URL}questions/`
});

export default function(){

    const [isLoading, setIsLoading] = useState(true);
    const [question, setQuestion] = useState({});
    const {slug} = useParams();

    useEffect(()=>{
        (async function(){
            if(isLoading){
                let question = await Modeler.promisify(Question.get, slug);
                setQuestion(question);
                setIsLoading(false);
            }
        })();
    }, [question])

    const replies = useMemo(()=>{
        return question.replies || [];
    }, [question]);

    const createCommentRecursiveBlocks = function(comment, level=1){
        return (
            <div className="flex flex-col gap-4 pb-12">
                {createCommentBlock(comment)}
                <div className={level === 1? 'pl-24': ''}>
                    {comment.replies.map(c => (
                        createCommentRecursiveBlocks(c, level+1)
                    ))}
                </div>
            </div>
        );
    }

    const createCommentBlock = function(_comment){
        const comment = Comment(_comment);

        return (
            <>
                <CommentCard
                    id={comment.commentId}
                    content={comment.content}
                    lastChange={comment.lastStatus}
                    upVotes={comment.upVotes}
                    downVotes={comment.downVotes}
                    user={comment.user}
                    commentedAt={comment.commentedAt}
                    userVotes={question.userVoteComments}
                    isReply={true}
                />
            </>
        )
    }

    return (
        <div className="my-container">
            <PageTitle className="mt-6">
                {question.title}
            </PageTitle>
            <div className="my-4">
                <QuestionInfo asDate={true} views={question.views} lastChange={question.publishedAt} category={question?.category || {}}/>
            </div>
            {question.hash? (<CommentCard
                id={question.commentId}
                title={question.title}
                content={question.content}
                lastChange={question.lastStatus}
                votes={question.votes}
                upVotes={question.upVotes}
                downVotes={question.downVotes}
                user={question.user}
                commentedAt={question.publishedAt}
                userVotes={question.userVoteComments}
            />): ''}

            {replies.length? (
                <>
                    <div className="flex flex-row justify-between my-4 items-center">
                        <p className="text-l-regular">{question.commentsCount} Answer</p>
                        <div className="flex flex-row gap-2.5">
                            <div className="tag-label non-hover text-s-regular disabled cursor-pointer">
                                Oldest
                            </div>
                            <div className="tag-label non-hover text-s-regular disabled cursor-pointer">
                                Newest
                            </div>
                            <div className="tag-label non-hover text-s-regular disabled cursor-pointer">
                                Voted
                            </div>
                            <div className="tag-label non-hover text-s-regular select-none">
                                Active
                            </div>
                        </div>
                    </div>
                    {replies.map(comment => {
                        return createCommentRecursiveBlocks(comment);
                    })}
                </>
            ): ''}
        </div>
    )
}
