import React, {useMemo, useState} from "react";

export default function({text, children}){

    const [isCollapsed, setCollapsed] = useState(true);

    const collapseTextClass = useMemo(()=>!isCollapsed? 'text-neutral-90': 'text-primary-surface', [isCollapsed]);
    const collapseBgClass = useMemo(()=>!isCollapsed? 'bg-primary-dropdown-pressed border-primary-focused': 'bg-primary-main border-primary-main', [isCollapsed]);

    return (
        <div className="w-full py-px">
            <div onClick={()=>setCollapsed(!isCollapsed)} className={'border cursor-pointer transition-all duration-200 rounded w-full flex flex-row items-center gap-4 p-3 ' + collapseTextClass + ' ' + collapseBgClass}>
                <svg className={"stroke-current transition-all duration-200 " + (isCollapsed? 'rotate-180':'')} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.28 10.5334L8.9333 6.18676C8.41997 5.67342 7.57997 5.67342 7.06664 6.18676L2.71997 10.5334" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>
                    {text}
                </p>
            </div>
            <div className={"overflow-hidden duration-400 transition-max-height"} style={isCollapsed? {maxHeight: 0}:{maxHeight: '200px'}}>
                <div className={"overflow-hidden px-4 py-5 rounded border-primary-focused mt-px border"}>
                    {children}
                </div>
            </div>
        </div>
    )
}
