import React from "react";
import DefaultImage from "../DefaultImage.js";

export default function ({image, name, position}){

    return (
        <div className="flex flex-col mb-auto lg:w-52 w-32 rounded-xl overflow-hidden">
            {image? (
                <img className="w-full object-cover lg:h-64 h-40" src={image}/>
            ): (
                <DefaultImage className="lg:h-64 h-40 mb-0"/>
            )}
            <div className="bg-primary-main text-neutral-10 text-center py-2 px-2">
                <p className="text-sm font-medium">{name}</p>
                <p className="text-sm">{position}</p>
            </div>
        </div>
    )


}
