import React from "react";

export default function ({className=''}){
    return (
        <div className={className + " w-full bg-primary-dropdown-pressed rounded-lg h-120 my-6 flex items-center justify-center"}>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M73.4 56.0667L62.9666 31.6667C61.0666 27.2 58.2333 24.6667 55 24.5C51.8 24.3334 48.7 26.5667 46.3333 30.8334L40 42.2C38.6666 44.6 36.7666 46.0334 34.7 46.2C32.6 46.4 30.5 45.3 28.8 43.1334L28.0666 42.2C25.7 39.2334 22.7666 37.8 19.7666 38.1C16.7666 38.4 14.2 40.4667 12.5 43.8334L6.7333 55.3334C4.66664 59.5 4.86664 64.3334 7.29997 68.2667C9.7333 72.2 13.9666 74.5667 18.6 74.5667H61.1333C65.6 74.5667 69.7666 72.3334 72.2333 68.6C74.7666 64.8667 75.1666 60.1667 73.4 56.0667Z" fill="#B0E6D9"/>
                <path d="M23.2332 27.9334C29.4556 27.9334 34.4999 22.8891 34.4999 16.6667C34.4999 10.4443 29.4556 5.40002 23.2332 5.40002C17.0108 5.40002 11.9666 10.4443 11.9666 16.6667C11.9666 22.8891 17.0108 27.9334 23.2332 27.9334Z" fill="#B0E6D9"/>
            </svg>
        </div>
    )
}
