import axios from 'axios';

export const BASE_URL = process.env.NODE_ENV === 'production' ? '/': "http://ternak-laravel.test/";
export const API_URL = `${BASE_URL}api/v1/`;

const serializeData = function(data){
    let formData = data;
    if(!(data instanceof FormData)){
        formData = new FormData;
        for(let name in data){
            let value = data[name];
            if(value instanceof FileList){
                for(let i in value){
                    if(i === 'length') continue;
                    let file = value[i];
                    formData.append(name, file);
                }
            } else {
                formData.append(name, value);
            }
        }
    }
    return formData;
}

export const axiosGet = function(url, config){
    return new Promise((resolve, reject) => {
        try {
            let headers = config?.headers || {}
            axios.get(url, {
                headers: {
                    ...headers,
                }
            }).then(r => {
                let response = r.data
                if ('success' in response && response.success) {
                    resolve(response.data);
                } else {
                    reject(response.message);
                }
            }).catch(e => {
                reject(e);
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const axiosPost = function(url, data={}, config={}){
    let headers = config?.headers|| {}
    return new Promise((resolve, reject) => {
        try {
            axios.post(url, serializeData(data), {
                headers: {
                    ...headers,
                }
            }).then(r => {
                let response = r.data
                if ('success' in response && response.success) {
                    resolve(response.data);
                } else {
                    reject(response.message);
                }
            }).catch(e => {
                reject(e);
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const parameterize = function(data){
    let queries = [];
    if(typeof data !== 'object'){
        return '';
    }
    for(let i in data){
        if(typeof data[i] !== 'object')
            queries.push(i+'='+data[i])
    }
    return '?' + queries.join('&');
}

export const fetchFromAPI = function(url){
    return axios.get(url, {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    });
}
