import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {MenuModel} from "../../lib/models/index.js";
import {API_URL} from "../../lib/core-x/Connection.js";

const Menus = Modeler.use({
    model: MenuModel,
    url: `${API_URL}menus`
})

export const FetchMenusThunk = createAsyncThunk('menus/fetch', async function(){
    return await Modeler.promisify(Menus.take, {limit: 120});
});
