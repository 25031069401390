import {createSlice} from "@reduxjs/toolkit";
import {FetchPostBySlug, FetchPostCountThunk, FetchPostsThunk, FetchRecentPostsThunk} from "../thunks/post-thunk.js";
import {parse} from "postcss";

const postSlice = createSlice({
    name: "postSlice",
    initialState: {
        posts: [],
        // Fulfilled pages
        fulfilled: [],
        currentPosts: [],
        currentCount: {},
        currentPost: {},
        recentPosts: null,
        isFetchingPostsLoading: false,
        isFetchingRecentPostsLoading: false,
        isFetchingPostsCount: false,
        isError: false,
    },
    reducers: {},
    extraReducers(builder){

        builder.addCase(FetchPostsThunk.pending, function(state, action){
           state.isFetchingPostsLoading = true;
           state.isError = false;
        });

        builder.addCase(FetchPostsThunk.rejected, function(state, action){
            state.isFetchingPostsLoading = false;
            state.isError = true;
        });

        builder.addCase(FetchPostsThunk.fulfilled, function(state, action){
            const {fulfilled: payloadFulfilled, posts} = action.payload;

            if(!state.fulfilled.some(f => (f.page === payloadFulfilled.page && f.limit === payloadFulfilled.limit))){
                state.fulfilled = [
                    ...state.fulfilled,
                    payloadFulfilled
                ];
                state.posts = [
                    ...state.posts,
                    ...posts
                ];
            }

            state.isFetchingPostsLoading = false;
            state.currentPosts = posts;
        })

        builder.addCase(FetchPostCountThunk.pending, function(state, action){
            state.isFetchingPostsCount = true;
        });

        builder.addCase(FetchPostCountThunk.fulfilled, function(state, action){
            state.currentCount = action.payload;
            state.isFetchingPostsCount = false;
        });

        builder.addCase(FetchRecentPostsThunk.pending, function(state, action){
            state.isFetchingRecentPostsLoading = true;
            state.isError = false;
        });

        builder.addCase(FetchRecentPostsThunk.rejected, function(state, action){
            state.isError = true;
            state.isFetchingRecentPostsLoading = false;
        });

        builder.addCase(FetchRecentPostsThunk.fulfilled, function(state, action){
            state.isFetchingRecentPostsLoading = false;
            state.isError = false;
            if(action.payload)
                state.recentPosts = action.payload;
        });

        builder.addCase(FetchPostBySlug.fulfilled, function(state, action){

            let post = action.payload;
            let statePostIndex = state.posts.findIndex(p => p.hash === post.hash);

            if(statePostIndex < 0){
                state.posts = [
                    ...state.posts,
                    post,
                ]
            } else {
                let posts = state.posts;
                posts[statePostIndex] = post;
                state.posts = posts;
            }

            state.currentPost = post;

        });

    }
});

export const SelectCurrentPosts = function(state){
    return state.postSlice.currentPosts;
};

export const SelectCurrentPostCount = function(state){
    return parseInt(state.postSlice.currentCount?.total);
}

export const SelectCurrentPost = function(state){
    return state.postSlice.currentPost;
}

export const SelectRecentPosts = function(state){
    return state.postSlice.recentPosts || [];
}

export const SelectRelatedPosts = function(state){
    return state.postSlice.currentPost?.relatedPosts || [];
}

/* Loaders */

export const SelectLoadingPosts = function(state){
    return state.postSlice.isFetchingPostsLoading;
}

export const SelectLoadingPostError = function(state){
    return state.postSlice.isError;
}

export const SelectLoadingRecentPosts = function(state){
    return state.postSlice.isFetchingRecentPostsLoading;
}

export const SelectLoadingPostsCount = function(state){
    return state.postSlice.isFetchingPostsCount;
}

export default postSlice.reducer;
