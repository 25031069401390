import {createAsyncThunk} from "@reduxjs/toolkit";
import Modeler from "../../lib/core-x/index.js";
import {PageModel} from "../../lib/models/index.js";
import {API_URL} from "../../lib/core-x/Connection.js";

const Pages = Modeler.use({
    model: PageModel,
    url: `${API_URL}pages`
})

export const FetchPageThunk = createAsyncThunk('pages/fetchBySlug', async function({slug}, {getState}){
    let state = getState();
    let {pages} = state.pageSlice;

    let selectedPage = pages.find(p => p.slug === slug);
    if(selectedPage){
        return selectedPage;
    }

    return await Modeler.promisify(Pages.get, slug);
});
