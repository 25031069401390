import React from 'react';
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " slick-arrow-custom"}
      style={{ ...style, display: "block", right: 25, zIndex: 20 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " slick-arrow-custom"}
      style={{ ...style, display: "block", left: 25, zIndex: 20 }}
      onClick={onClick}
    />
  );
}

export default function ReactCarousel({children}){

  const settings = {
    dots: true,
    arrows: true,
    appendDots: dots => (
      <div style={{
        position: 'absolute',
        bottom: '24px',
      }}>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <a
        className={'block rounded-full bg-white border border-white m-2'}
        style={{
          width: "15px",
          height: '15px',
        }}
      >

      </a>
    ),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <div className={'w-full relative'}>
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
}
