import React from 'react';
import Banner from "../layouts/Banner.js";
import banner from "../assets/banner.png";
import domba from "../assets/domba.png";

export default function Investor({}){
  return (
    <div>
      <Banner title={"Investor"}>
        Cara mudah investasi ke peternakan domba di Kabupaten Malang Kecamatan Dampit
      </Banner>
      <div className={'my-container py-24'}>
        <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
          <div className={'relative w-64 min-w-[16rem]'}>
            <span className={'block absolute w-full h-[120%] rounded-lg transform -top-[10%] -left-[25%] bg-primary-image'}/>
            <img className={'w-full rounded-lg drop-shadow-2xl'} src={domba}/>
          </div>
          <div className={'flex flex-col gap-4 items-start 2xl:max-w-5xl max-w-3xl'}>
            <div className={'space-y-2'}>
              <p className={'text-black text-lg font-medium'}>
                Investasi Domba
              </p>
              <h2 className={'text-3xl text-primary font-bold'}>
                Peternakan Dampit adalah platform  untuk mempermudah orang dalam berinvestasi
              </h2>
            </div>
            <h6 className={'text-lg '}>
              Dapatkan pelatihan,  dan dukungan yang kamu butuhkan untuk membangun bisnis penjualan domba online yang selalu kamu inginkan.            </h6>
          </div>
        </div>
        <div className={"py-40 flex flex-row justify-between items-center gap-20"}>
          <div className={'flex flex-col gap-4 items-start 2xl:max-w-5xl max-w-3xl'}>
            <div className={'space-y-2'}>
              <p className={'text-black text-lg font-medium'}>
                Keuntungan berinvestas di  Peternakan Dampit
              </p>
              <h2 className={'text-3xl text-primary font-bold'}>
                Apa sih yang membuat kalian harus bergabung untuk investasi di Peternakan Dampit
              </h2>
            </div>
            <ul className={'text-lg list-disc pl-5'}>
              <li>Uang anda terjamin</li>
              <li>Tidak terlalu mengambil resiko yang besar</li>
              <li>Investor dapat melihat ternak secara langsung ataupun lewat monitor</li>
            </ul>
          </div>
          <div className={'relative w-64 min-w-[16rem]'}>
            <span className={'block absolute w-full h-[80%] rounded-lg transform top-[10%] -right-[25%] bg-primary-image'}/>
            <img className={'w-full rounded-lg drop-shadow-2xl'} src={domba}/>
          </div>
        </div>
      </div>
    </div>
  )
}
