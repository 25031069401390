import React, {useEffect, useMemo, useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import NavItem from "../components/NavItem.js";
import OuterClick from "../components/OuterClick.js";
import footerLogo from '../assets/footer-logo.png';
import classNames from "classnames";
import {useMenu} from "../hooks/menu-hook.js";

export default function Navbar(){

    const {data: menus} = useMenu();
    const [focusedMenu, setFocusedMenu] = useState(null)
    const [observeRouteChange, setRouteChange] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)

    const location = useLocation();

    useEffect(() => {
        if(focusedMenu){
            setFocusedMenu(null);
        }
        setRouteChange(true);
        setMobileOpen(false);
        setTimeout(()=>{
            setRouteChange(false);
        }, 300);
    },[location.pathname]);

    const menuContains = function(menu){
        return menu?.children?.some(child=>{
            return (('/' + child.slug) === location.pathname) || menuContains(child)
        })
    }

    return (
      <div className="w-full bg-neutral-10 h-20 fixed z-50 top-0 left-0 bg-white">
          <div className={'relative'}>
              <div className="my-container flex flex-row justify-between items-center h-full">
                  <div className='text-2xl font-bold text-primary py-2 h-20 flex items-center justify-center'>
                      Peternakan
                  </div>
                  <div className="ml-auto hidden lg:flex flex-row gap-6 select-none text-neutral-100">
                      {menus.map((menu, index)=>(
                        <div key={index} className="menu">
                            {menu.children?.length? (
                              <OuterClick onClick={()=>menu.slug === focusedMenu? setFocusedMenu(null): ''}>
                                  <div>
                                      <div onClick={()=>setFocusedMenu(menu.slug)} className={'menu'}>
                                          <p className={"flex cursor-pointer flex-row gap-2 items-center " + (menuContains(menu)? 'active': '')}>
                                              {menu.label}
                                              <span className="icon icon-caret"/>
                                          </p>
                                      </div>
                                      <div className={"absolute -ml-4 mt-8 top-full w-52 flex-col font-normal items-start rounded-lg bg-white " + (focusedMenu === menu.slug? 'flex' : 'hidden')}>
                                          {menu.children?.map((item, index)=>(
                                            <NavItem index={index === 0? -1: index + 1 === menu.children.length? 1: 0} key={index} menu={item} isRight={false}/>
                                          )) || ''}
                                      </div>
                                  </div>
                              </OuterClick>
                            ): (
                              <NavLink to={menu.slug}>
                                  <p className="flex flex-row gap-2 items-center">
                                      {menu.label}
                                  </p>
                              </NavLink>
                            )}
                        </div>
                      ))}
                      <Link to={'/login'} className={"readMore"}>
                          Masuk
                      </Link>
                  </div>
                  <div className={'lg:hidden text-black'} onClick={()=>setMobileOpen(!mobileOpen)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className={'w-10  h-10'} viewBox="0 0 48 48">
                          <path d="M41,14H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,14Z" fill="#6f7380"/>
                          <path d="M41,26H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,26Z" fill="#6f7380"/>
                          <path d="M41,38H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,38Z" fill="#6f7380"/>
                      </svg>
                  </div>
                  {mobileOpen? (
                    <div className="lg:hidden flex flex-col w-full bg-white absolute left-0 top-20" id="mobile-menu">
                        {menus.map((menu, index)=>(
                          <MobileMenu key={index} menu={menu} isFirst={true} isParentOpen={observeRouteChange}/>
                        ))}
                        <Link to={'/login'} className={"button"}>
                            Masuk
                        </Link>
                    </div>
                  ): ''}
              </div>
          </div>
      </div>
    )
}

const CaretIcon = ({className})=>{
    return (
      <svg className={classNames(className, 'stroke-current')} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
}

const MobileMenu = function({menu: {label, slug, children}, isFirst = false, isParentOpen = false, level = 0}){

    const [isChildOpen, setChildOpen] = useState(false);

    useEffect(()=>{
        if(!isParentOpen){
            setChildOpen(false);
        }
    }, [isParentOpen]);

    let levelClasses = {
        0: 'pl-0',
        1: 'pl-2',
        2: 'pl-4',
        3: 'pl-6',
        4: 'pl-8'
    }

    return (
      <>
          <div className={isFirst? 'bg-white': 'bg-neutral-200'}>
              {children?.length? (
                <a href="#" onClick={() => setChildOpen(!isChildOpen)} className="flex flex-row justify-between items-center px-3 py-3 rounded-md text-base font-medium">
                    <span className={levelClasses[level]}>
                        {label}
                    </span>
                    <CaretIcon className={classNames({'rotate-0': !isChildOpen, 'rotate-90': isChildOpen}, 'w-5 h-5 transform transition-all')}/>
                </a>
              ):(
                <NavLink to={slug} onClick={() => setChildOpen(!isChildOpen)} className="flex flex-row justify-between items-center px-3 py-3 rounded-md text-base font-medium">
                    <span className={'pl-' + level}>
                        {label}
                    </span>
                </NavLink>
              )}
              <div className={classNames('overflow-y-hidden transition-all',{'max-h-0': !isChildOpen, 'max-h-[700px]': isChildOpen})} style={{transition: 'max-height .5s'}}>
                  {children?.length? children.map((child,index) =>
                    <MobileMenu key={index} menu={child} isParentOpen={isChildOpen} level={level + 1}/>
                  ): ''}
              </div>
          </div>
      </>
    )

}
