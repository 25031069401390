import React, {useEffect, useMemo, useState} from "react";
import {resolveImageURL, resolveURL} from "../../lib/functions.js";
import HierarchyCard from "../../components/core/HierarchyCard.js";
import {useDispatch, useSelector} from "react-redux";
import {SelectMediaByHash} from "../../redux-store/slices/media-slice.js";
import {FetchMediaThunk} from "../../redux-store/thunks/media-thunk.js";
import ImgsViewer from "react-images-viewer";
import classNames from "classnames";

function Media ({hash, className = ''}) {

    const dispatch = useDispatch();
    const media = useSelector(SelectMediaByHash(hash));
    const [isOpen, setOpen] = useState(false);

    const resolvedMedia = useMemo(()=>{
        return media? {
            ...media,
            src: resolveImageURL(media)
        } :media;
    }, [media]);

    useEffect(()=>{
        dispatch(FetchMediaThunk({hash}));
    }, [hash]);

    return media? (
        <>
            {media.src? (
                <>
                    <img onClick={()=>setOpen(true)} className={classNames('cursor-pointer w-full my-6', className)} src={resolveImageURL(media)} alt={media.caption}/>
                    <ImgsViewer
                        imgs={[resolvedMedia]}
                        isOpen={isOpen}
                        onClose={()=>setOpen(false)}
                    />
                </>
            ): ''}
        </>
    ): '';
}

export default Media;
