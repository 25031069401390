import React from "react";

const PulsePage = function(){
    return (
        <div className="my-container pt-4 pb-40 flex lg:flex-row flex-col items-start gap-10">
            <div className="lg:w-2/3 flex-grow w-full min-w-fit p-5 h-120 mt-4 flex flex-col gap-4 rounded-xl" style={{'minWidth': '33.33%'}}>
                <span className='block mb-6 w-96 h-12 rounded-xl bg-gray-200 pulse'/>
                <span className='block bg-gray-200 pulse anim-delay-1 w-[60%] h-8 rounded-xl'/>
                <span className='block bg-gray-200 pulse anim-delay-1 w-[40%] h-8 rounded-xl'/>
                <span className='block bg-gray-200 pulse anim-delay-1 w-[56%] h-8 rounded-xl'/>
                <span className='block bg-gray-200 pulse anim-delay-1 w-[80%] h-8 rounded-xl'/>
                <span className='block bg-gray-200 pulse anim-delay-1 w-[30%] h-8 rounded-xl'/>
                <span className='block bg-gray-200 pulse anim-delay-1 w-full h-120 rounded-xl'/>
            </div>
            <div className="lg:w-1/3 w-full min-w-fit p-5 bg-white mt-4 flex flex-col gap-2 rounded-xl" style={{'minWidth': '33.33%'}}>
                <div className='block bg-gray-200 pulse w-40 h-8 rounded-xl'/>
                <div className='side-splitter-1'/>
                <div className='block bg-gray-200 pulse anim-delay-1 w-[20%] h-6 rounded-xl'/>
                <div className='side-splitter-2'/>
                <div className='block bg-gray-200 pulse anim-delay-2 w-[20%] h-6 rounded-xl'/>
                <div className='side-splitter-2'/>
                <div className='block bg-gray-200 pulse anim-delay-3 w-[50%] h-6 rounded-xl'/>
                <div className='side-splitter-2'/>
                <div className='block bg-gray-200 pulse anim-delay-4 w-[30%] h-6 rounded-xl'/>
                <div className='side-splitter-2'/>
                <div className='block bg-gray-200 pulse anim-delay-5 w-[40%] h-6 rounded-xl'/>
                <div className='side-splitter-2'/>
                <div className='block bg-gray-200 pulse anim-delay-6 w-[60%] h-6 rounded-xl'/>
            </div>
        </div>
    )
}

export default PulsePage
