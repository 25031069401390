import footerLogo from '../assets/footer-logo.png';

export default function(){

    const socialIcons = [
        {
            icon: 'fb',
            source: 'https://facebook.com'
        },
        {
            icon: 'ig',
            source: 'https://instagram.com'
        },
        {
            icon: 'yt',
            source: 'https://youtube.com'
        }
    ]

    return (
        <div className="bg-primary">
            <div className="my-container w-full text-l-regular py-24 text-neutral-10">
                <div className="flex flex-col md:flex-row xl:flex-wrap-0 flex-wrap xl:gap-0 gap-4">
                    <div className="lg:w-1/2 flex-grow flex flex-col items-start gap-4 lg:pr-12">
                        <p className="min-w-fit text-4xl lg:text-5xl font-semibold">
                            Peternakan
                        </p>
                        <p>
                            Peternakan domba online, mulai investasi di ternak domba online
                        </p>
                        <div className="flex-grow flex flex-col gap-4">
                            <p className="flex flex-row items-center gap-4">
                                <a href="https://facebook.com">
                                    <span className={"icon w-8 h-8 icon-fb"}/>
                                </a>
                                <a href="https://instagram.com">
                                    <span className={"icon w-8 h-8 icon-ig"}/>
                                </a>
                                <a href="https://youtube.com">
                                    <span className={"icon w-8 h-8 icon-yt"}/>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="lg:w-1/2 flex-grow lg:px-12 flex flex-col gap-4">
                        <p className="text-4xl font-semibold min-w-fit">
                            Kontak
                        </p>
                        <div className="text-l-regular">
                          Desa Bumirejo Kec Dampit Kab Malang
                            <br/>
                            <a target={'_blank'} rel={'noopener noreferrer'} href={'peternakandampit@gmail.com'}>
                                Email: peternakandampit@gmail.com
                            </a>
                            <br/>
                            {/*<a target={'_blank'} rel={'noopener noreferrer'} href={'https://wa.me/+628888888888'}>*/}
                            {/*    Telepon: 0888888888*/}
                            {/*</a>*/}
                            {/*<br/>*/}
                            {/*<a target={'_blank'} rel={'noopener noreferrer'} href={'https://wa.me/+628888888888'}>*/}
                            {/*    Whatsapp: 0888888888*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
