import {createSlice} from "@reduxjs/toolkit";
import {FetchMediaThunk} from "../thunks/media-thunk.js";

const mediaSlice = createSlice({
    name: "mediaSlice",
    initialState: {
        items: [],
    },
    reducers: {},
    extraReducers(builder){
        builder.addCase(FetchMediaThunk.fulfilled, function(state, action){
            if(!state.items.some(a => a.hash === action.payload.hash)){
                state.items = [
                    ...state.items,
                    action.payload
                ]
            }
        });
    }
});

export const SelectMediaByHash = function(hash){
    return function(state){
        return state.mediaSlice.items.find(i => i.hash + '' === hash + '');
    }
};


export default mediaSlice.reducer;
