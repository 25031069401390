import React, {useEffect, useMemo, useState} from "react";
import PostCard from "../components/posts/PostCard.js";
import {useLocation} from "react-router-dom";
import Pagination from "../components/core/Pagination.js";
import PulseCard from "../components/core/loaders/PulseCard.js";
import {useSelector, useStore} from "react-redux";
import {
    SelectCurrentPostCount,
    SelectCurrentPosts,
    SelectLoadingPosts, SelectLoadingPostsCount, SelectLoadingRecentPosts,
    SelectRecentPosts
} from "../redux-store/slices/post-slice.js";
import {FetchPostCountThunk, FetchPostsThunk, FetchRecentPostsThunk} from "../redux-store/thunks/post-thunk.js";
import PostBanner from "../components/posts/PostBanner.js";
import PulseBanner from "../components/core/loaders/PulseBanner.js";
import PulsePagination from "../components/core/loaders/PulsePagination.js";
import Banner from "../layouts/Banner.js";
import PostSidebar from "../components/posts/PostSidebar.js";

const limit = 8;

export default function Posts({}){

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(parseInt(new URLSearchParams(location.search).get("page") || 1));

    const store = useStore();
    const recentPosts = useSelector(SelectRecentPosts)
    const posts = useSelector(SelectCurrentPosts)
    const totalPosts = useSelector(SelectCurrentPostCount);
    const isFetching = useSelector(SelectLoadingPosts);
    const isFetchingRecent = useSelector(SelectLoadingRecentPosts);
    const isFetchingPostCount = useSelector(SelectLoadingPostsCount);

    useEffect(()=>{
        if(page <= 0){
            setPage(1);
            return;
        }
        store.dispatch(FetchPostsThunk({page, limit}))
        store.dispatch(FetchPostCountThunk({}));
        store.dispatch(FetchRecentPostsThunk({}));
        window.history.replaceState(null, "React State", "?page=" + page);
    }, [page])

    const totalPages = useMemo(()=>{
        return (totalPosts - 1) / limit + 1;
    }, [totalPosts]);


    const changePage = function(num){
        setIsLoading(true);
        setPage(num);
    }


    return (
      <div>
          <Banner title={"Berita"}>
              Temukan berita menarik seputar domba, penyakit domba dan pakan domba
          </Banner>
          <div className="my-container py-24">
              <div className="text-black">
                  <div className={'flex flex-wrap lg:flex-row w-full lg:gap-32'}>
                      <div className="w-full lg:w-1/3 flex-grow">
                          <div className="grid grid-cols-1 gap-24 pb-12 transition-grid-template h-fit duration-300">
                              {(isFetching)? (
                                [1, 2, 3, 4, 5, 6, 7, 8].map(num => (
                                  <PulseCard key={num}/>
                                ))
                              ): posts.map(post => (
                                <div>
                                    <PostCard
                                      withContent={true}
                                      content={post.content}
                                      key={post.hash}
                                      title={post.title}
                                      category={post.category}
                                      categorySlug={post.categorySlug}
                                      date={post.publishedAt}
                                      image={post.image}
                                      slug={post.slug}/>
                                </div>
                              ))}
                          </div>
                          {isFetchingPostCount? (
                            <PulsePagination/>
                          ): (
                            <Pagination page={page} totalPages={totalPages} onPageChange={changePage}/>
                          )}
                      </div>
                      <div className="w-full lg:w-1/3 max-w-[330px] lg:sticky top-24">
                          {recentPosts?.length ? (
                            <>
                                {recentPosts?.length? (<PostSidebar posts={recentPosts}/>): ""}
                            </>
                          ) : (
                            <PulseBanner/>
                          )}
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}
