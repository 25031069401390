import Home from "../pages/Home.js";
import Profile from "../pages/Profile.js";
import Policies from "../pages/Policies.js";
import Layout from "../layouts/Layout.js";
import General from "../layouts/General.js";
import PostLayout from "../layouts/PostLayout.js";
import Gallery from "../pages/Gallery.js";
import UnderConstruction from "../pages/UnderConstruction.js";
import Posts from "../pages/Posts.js";
import Forum from "../pages/Forum.js";
import ForumDetails from "../pages/ForumDetails.js";
import Archive from "../pages/Archive.js";
import Investor from "../pages/Investor.js";
import AyoTernak from "../pages/AyoTernak.js";

export default [
    {
        path: '/',
        component: <Layout />,
        children: [
            {
                path: '',
                component: <Home/>,
            },
            {
                path: 'profil',
                component: <Profile/>
            },
            {
                path: 'investor',
                component: <Investor/>
            },
            {
                path: 'oyiFarm',
                component: <AyoTernak/>
            },
            {
                path: 'peraturan',
                component: <Policies/>
            },
            {
                path: 'galeri',
                component: <Gallery/>
            },
            {
                path: 'forum',
                component: <Forum/>
            },
            {
                path: 'forum/pertanyaan/:slug',
                component: <ForumDetails/>
            },
            {
                path: 'berita',
                component: <Posts/>
            },
            {
                path: 'berita/:slug',
                component: <PostLayout/>,
            },
            {
                path: 'categories/:slug',
                component: <Archive/>
            },
            {
                path: '*',
                component: <General/>,
            },
            {
                path: 'coming-soon',
                component: <UnderConstruction/>,
            }
        ]
    },
]
