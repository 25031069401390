import React from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import NavItem from "./NavItem.js";

export default function({index, menu, isRight, level=1, isFirst=false, isLast=false}){

    // index = -1 is first child. index = 1 is last child

    const location = useLocation();
    const indexClass = (index === -1? ' rounded-t-lg': index === 1? ' rounded-b-lg': index === 2? 'rounded-lg': '');
    const classes = 'left-[100%] left-[200%] left-[300%]'
    const roundedClasses = (isFirst? "rounded-t-lg":'') + " " + (isLast? "rounded-b-lg": '');

    return (
        <>
            {menu.children?.length? (
                <div className={"w-full group relative " + roundedClasses}>
                    <div className={(menu.children.some(c => location.pathname.includes(c.slug))? 'dropdown-label menu-item cursor-pointer active': 'dropdown-label menu-item g-hover:text-neutral-100 g-hover:bg-neutral-20 g-hover:font-medium') + indexClass}>
                        <p className="text-sm">
                            {menu.label}
                        </p>
                        <span className="icon icon-caret -rotate-90 -mr-2"/>
                    </div>
                    <div className={"absolute left-full rounded-lg top-0 w-52 g-hover:flex hidden flex-col font-normal items-start bg-white"}>
                        {menu.children.map((item, index)=>(
                            <NavItem
                                index={menu.children.length === 1? 2 : (index === 0? -1: index + 1 === menu.children.length? 1: 0)}
                                isFirst={index === 0}
                                isLast={index === menu.children.length}
                                menu={item}
                                isRight={true}
                                level={level+1}
                                key={index}
                            />
                        )) || ''}
                    </div>
                </div>
            ): (
                <NavLink to={menu.slug} className={' w-full submenu-item px-4 py-2 text-m-regular text-neutral-90 justify-between hover:text-neutral-100 hover:bg-neutral-20 hover:font-medium ' + indexClass}>
                    {menu.label}
                </NavLink>
            )}
        </>
    )
}
