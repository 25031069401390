/*
Blog fetch All, fetch by.., etc
There are two types of fetch:
* Fetch All (without limitation)
* Fetch By (with limitation)

It returns a promisifiable and connectable object
*/

import {axiosGet, parameterize} from "./Connection.js";

const modelize = function(dataResponse, singleModelObject){
    if(Array.isArray(dataResponse)){
        return dataResponse.map(item => singleModelObject(item));
    }
    return singleModelObject(dataResponse? dataResponse: {});
}

const _createCallback = function(url, pipe, config, ...args){
    let callback = args.find(a=>typeof a === 'function');
    if(typeof callback === 'function'){
        axiosGet(url + parameterize(config)).then(r=>{
            callback(null, modelize(r, pipe));
        }).catch(e=>{
            callback(e, null);
        });
        return false;
    }
    return true;
}

const Instance = function(url, config={}, pipe){
    const take = function(args, cb){
        let count = -1;
        let config = {limit: count};
        if(typeof args === 'object'){
            config = args;
            count = args.limit || count;
            config.limit = count;
        } else if(typeof args === 'function'){
            cb = args;
        }

        if(_createCallback(url, pipe, config, count, cb))
            return Instance(url, config, pipe);
    }
    // Gets count only
    const count = function(cb){
        if(_createCallback(url, pipe, config, cb))
            return Instance(url, {...config, isCount: true}, pipe);
    }
    const get = function(id, cb){
        if(_createCallback(url+id, pipe, config, cb))
            return Instance(url + id, config, pipe);
    }

    return {
        take,
        count,
        get
    }
}

export const use = function(item){

    let {model, url} = item;
    if(!model || !url){
        throw "Model and URL must be defined";
    }

    return {
        take: function(args, cb){
            let count = -1;
            let config = {limit: count};
            if(typeof args === 'object'){
                config = args;
                count = args.limit || count;
                config.limit = count;
            } else if(typeof args === 'function'){
                cb = args;
            }

            if(_createCallback(url, model, config, count, cb))
                return Instance(url, config, model);
        },
        count: function(config={}, cb){
            if(typeof config === 'function'){
                cb = config;
                config = {};
            }
            if(_createCallback(url, model, config, cb))
                return Instance(url, {isCount: true}, model);
        },
        get: function(id, cb){
            if(_createCallback(url + id, model, {}, cb))
                return Instance(url + id, {}, model);
        }
    }
}

export const promisify = function(func, ...args){
    return new Promise((resolve, reject)=>{
       return func(...args, (err, res)=>{
           if(err)
               reject(err);
           else
               resolve(res);
       });
    });
}

export default {
    use,
    promisify
}
