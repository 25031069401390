import React, {useEffect, useMemo} from "react";
import {resolveImageURL, resolveURL} from "../../lib/functions.js";
import HierarchyCard from "../../components/core/HierarchyCard.js";
import {useDispatch, useSelector} from "react-redux";
import {FetchHierarchyThunk} from "../../redux-store/thunks/hierarchy-thunk.js";
import {SelectCurrentHierarchy} from "../../redux-store/slices/hierarchy-slice.js";

function Hierarchy ({id}) {

    const dispatch = useDispatch();
    const hierarchy = useSelector(SelectCurrentHierarchy);

    useEffect(()=>{
        dispatch(FetchHierarchyThunk({id}));
    }, [id]);

    const levels = useMemo(()=>{
        return (hierarchy?.items ?? []).reduce((prev, curr) => {
            let level = curr.level;
            if(level in prev){
                prev[level].push(curr);
            } else {
                prev[level] = [curr];
            }
            return prev;
        }, {});
    }, [hierarchy]);

    return hierarchy? (
        <>
            <div className={'flex flex-col gap-8 my-4'}>
                {Object.keys(levels).map(level => (
                    <div key={level} className="flex justify-center items-center gap-8 flex-wrap">
                        {levels[level].map((item, index) => (
                            <HierarchyCard key={index} name={item.name} position={item.position} image={resolveImageURL(item.media)}/>
                        ))}
                    </div>
                ))}
            </div>
        </>
    ): '';
}

export default Hierarchy;
